import { Auth } from 'aws-amplify';
import { useCallback, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import PageContainer from './layouts/PageContainer';

const ProtectedRoute = () => {
  const navigate = useNavigate();

  const checkUserToken = useCallback(async () => {
    try {
      const userToken = (await Auth.currentSession()).getIdToken().getJwtToken();

      if (!userToken) {
        return navigate('/login');
      }
    } catch (error) {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    checkUserToken();
  }, [checkUserToken]);

  return (
    <PageContainer>
      <Outlet />
    </PageContainer>
  );
};
export default ProtectedRoute;
