import { PlusCircleOutlined } from '@ant-design/icons';

interface AddButtonProps {
  onClick?: () => void;
  title: string;
}

const AddButton = ({ onClick, title }: AddButtonProps) => {
  return (
    <>
      <button
        className={`hover:cursor-pointer rounded-[5px] flex justify-between items-center px-4
        bg-primary text-white hover:bg-[#5f52a0] focus:bg-primary 
        focus:text-white hover:text-white border-none w-fit h-[40px]`}
        onClick={onClick}
      >
        <div className={`flex items-center w-full gap-2`}>
          <p className={`m-auto`}>{title}</p>
          <PlusCircleOutlined className={`border-none text-[20px] text-white`} />
        </div>
      </button>
    </>
  );
};

export default AddButton;
