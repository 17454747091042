import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { OrderDetailType } from '../UserProfileDetail.page';
interface UserOrderDetailProps {
  data: OrderDetailType[];
}
const UserOrderDetail = ({ data }: UserOrderDetailProps) => {
  const columns: ColumnsType<OrderDetailType> = [
    {
      title: 'Type',
      dataIndex: 'type',
      className: 'column-first-item',
      key: 'type',
      render: (text) => <a>{data.indexOf(text)}</a>,
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      render: (_, { subject }) => (
        <div className=" flex flex-col">
          {subject.map((data) => {
            return <p>{data}</p>;
          })}
        </div>
      ),
    },
    {
      title: 'Dose',
      dataIndex: 'dose',
      key: 'dose',
      render: (_, { dose }) => (
        <div className=" flex flex-col">
          {dose.map((data) => {
            return <p>{data}</p>;
          })}
        </div>
      ),
    },
    {
      title: 'Qty',
      key: 'qty',
      dataIndex: 'qty',
      render: (_, { qty }) => (
        <div className=" flex flex-col">
          {qty.map((data) => {
            return <p>{data} box</p>;
          })}
        </div>
      ),
    },
    {
      title: 'Price',
      key: 'price',
      dataIndex: 'price',
      render: (_, { price }) => (
        <div className=" flex flex-col">
          {price.map((data) => {
            return <p>CA${data}</p>;
          })}
        </div>
      ),
    },
  ];
  return (
    <div className="">
      <div className="mt-[20px] pl-[10px] pr-[20px] flex flex-row justify-between">
        <p className=" text-[#4C4C4C] text-[20px] font-bold">#123456</p>
        <p className="text-[#4C4C4C] text-[16px]">23/12/2024</p>
      </div>
      <div className="">
        <Table className="table-user-profile " columns={columns} dataSource={data} />
      </div>
      <div className="flex flex-row justify-end">
        <div className="w-fit h-[53px] p-[10px] flex justify-center mr-[10px]">
          <p className="text-16px text-[#919191]">Authorizing date and time: 2024/07/05 at 5:11</p>
        </div>
        <button className="w-[200px] h-[53px] p-[10px] border-solid border-primary rounded-lg border-[1px] text-primary text-[16px] flex justify-center mr-[10px]">
          Reject
        </button>
        <button className="w-[200px] h-[53px] p-[10px] bg-primary text-white rounded-lg text-[16px] flex justify-center">
          Approve
        </button>
      </div>
    </div>
  );
};

export default UserOrderDetail;
