import NotFound from '@assets/images/icons/not-found.svg';
import { Image } from 'antd';
import { Link } from 'react-router-dom';

interface NoMatchProps {
  title: string;
  description: string;
  className: string;
  pathName: string;
  content?: string;
}

const NoMatch = ({ title, description, className, pathName, content }: NoMatchProps) => (
  <div className={className}>
    <h1 className={`text-primary font-semibold text-[32px]`}>{content}</h1>
    <Image className={`w-full h-full`} src={NotFound} preview={false} height={'auto'} />
    <h1 className={`text-center font-semibold text-primary m-auto`}>{title}</h1>
    <h3>
      <Link to={pathName}>{description}</Link>
    </h3>
  </div>
);
export default NoMatch;
