import { LeftOutlined } from '@ant-design/icons';
import accessCodeApi from '@apis/accessCode.api';

import { Company } from '@models/AccessCode.model';
import routes from '@routes';
import { convertNameCompanyAccessCodeData } from '@utils/convertNameCompanyAccessCodeData';

import { Button, Form, Input, message } from 'antd';
import axios, { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AccessCodeSelect.scss';
interface EditAccessForWholeCompanyProps {
  companyDetail: Company;
}
const EditAccessForWholeCompany = ({ companyDetail }: EditAccessForWholeCompanyProps) => {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<'idle' | 'failed' | 'success'>('idle');
  const navigate = useNavigate();
  const [errorContent, setErrorContent] = useState('');
  const [, forceUpdate] = useState({});
  const convertData = convertNameCompanyAccessCodeData(companyDetail);

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    if (status === 'idle') return;
    if (status === 'failed') {
      message.error(errorContent);
      setStatus('idle');
    }
    if (status === 'success') {
      message.success('Successful edited');
      navigate(routes.AccessCode.path + 'company');
    }
  }, [status, errorContent, navigate]);
  const onFinish = async (values: any) => {
    try {
      if (companyDetail) {
        const result = {
          companyName: values.companyName,
          companyDomain: values.companyDomain,
        };
        setIsLoading(true);
        const response = await accessCodeApi.editAccessCodeForWholeCompany(
          companyDetail.id,
          result
        );
        if (response.data.success) {
          setStatus('success');
        }
      }
    } catch (err) {
      const errors = err as Error | AxiosError;
      if (!axios.isAxiosError(errors)) {
        setErrorContent(errors.message);
      }
      const axiosErr = errors as AxiosError;
      if (!Array.from(axiosErr.response?.data.errors).length) {
        setErrorContent(axiosErr.response?.data.message);
      } else {
        setErrorContent(axiosErr.response?.data.errors);
      }
      setStatus('failed');
    } finally {
      setIsLoading(false);
    }
  };
  const onFinishFailed = (errorInfo: any) => {};
  const validateCompanyDomain = (value: string) => {
    return /^@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/.test(value)
      ? Promise.resolve()
      : Promise.reject('Right format: @abc.xyz');
  };
  return (
    <>
      {companyDetail && (
        <Form
          form={form}
          layout="vertical"
          style={{ maxWidth: '100%' }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className={`accessCompany flex flex-col gap-6`}
          initialValues={{
            ...convertData,
          }}
        >
          <div className={`w-full h-fit flex flex-rows justify-between`}>
            <div className={`flex flex-row justify-center items-center gap-8`}>
              <LeftOutlined
                className={`text-primary text-[22px]`}
                onClick={() => {
                  navigate(routes.AccessCode.path + 'company');
                }}
              />
              <div className={`text-3xl font-bold text-primary`}>A specific company</div>
            </div>
            <Form.Item shouldUpdate>
              {() => (
                <Button
                  type="primary"
                  htmlType="submit"
                  className={`w-80 h-fit p-3 rounded-md
                    text-[16px] font-bold text-white
                    bg-primary border-0
                    cursor-pointer
                    disabled:bg-[#a6a2bc] disabled:hover:bg-[#a6a2bc]
                    disabled:hover:text-white disabled:text-white
                    disabled:border-none disabled:cursor-not-allowed`}
                  disabled={
                    isLoading ||
                    !!form.getFieldsError().filter(({ errors }) => errors.length).length ||
                    form.getFieldValue('companyDomain') === '' ||
                    form.getFieldValue('companyName') === ''
                  }
                >
                  {!isLoading && <>Save</>}
                  {isLoading && <>Saving...</>}
                </Button>
              )}
            </Form.Item>
          </div>
          <div className={`content flex flex-col justify-center gap-4`}>
            <Form.Item
              label={'1. Name of the company'}
              name={'companyName'}
              rules={[
                {
                  required: true,
                  message: 'Please enter the name of the company',
                },
              ]}
            >
              <Input
                placeholder="Enter the name of company here"
                maxLength={50}
                showCount
                disabled={isLoading}
              />
            </Form.Item>
            <Form.Item
              className={`input`}
              label="2. Email Domain"
              name="companyDomain"
              rules={[
                { required: true, message: 'Please enter the email domain' },
                () => ({
                  validator(_, value) {
                    return validateCompanyDomain(value);
                  },
                }),
              ]}
            >
              <Input
                type="text"
                placeholder="Enter email domain (E.g. anahealth.com)"
                showCount
                maxLength={50}
              />
            </Form.Item>
          </div>
        </Form>
      )}
    </>
  );
};

export default EditAccessForWholeCompany;
