
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ReplyChatState } from "./replyChat.state";



const initialState: ReplyChatState = {
    senderId: '',
    text: '',
    chatId:'',
    messageId:'',
    rating: null,
};

const userSlice = createSlice({
  name: 'reply',
  initialState,
  reducers: {
    addReply(state, action: PayloadAction<ReplyChatState>) {
      state.senderId = action.payload.senderId;
      state.text = action.payload.text;
      state.chatId = action.payload.chatId;
      state.messageId = action.payload.messageId
      state.rating = action.payload.rating;
    },
    resetReply() {
      return {
        senderId: '',
        text: '',
        chatId: '',
        messageId:'',
        rating: null,
      };
    },
  }
});

export const { addReply, resetReply } = userSlice.actions;
export default userSlice.reducer;