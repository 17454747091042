import { LeftOutlined } from '@ant-design/icons';
import accessCodeApi from '@apis/accessCode.api';
import { Company } from '@models/AccessCode.model';
import routes from '@routes';
import { convertNameCompanyAccessCodeData } from '@utils/convertNameCompanyAccessCodeData';
import { Button, Form, Input, message } from 'antd';
import axios, { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../add-access-code/AccessForIndividualsOfCompany.scss';
import UploadFileForm from '../add-access-code/components/UploadFileForm';
import './AccessCodeSelect.scss';
interface AccessForIndividualsOfCompanyProp {
  individualsCompanyDetail: Company;
}

const EditAccessForIndividualsOfCompany = ({
  individualsCompanyDetail,
}: AccessForIndividualsOfCompanyProp) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<'idle' | 'failed' | 'success'>('idle');
  const navigate = useNavigate();
  const [errorContent, setErrorContent] = useState('');
  const [, forceUpdate] = useState({});

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    if (status === 'idle') return;
    if (status === 'failed') {
      message.error(errorContent);
      setStatus('idle');
    }
    if (status === 'success') {
      message.success('Successful added');
      navigate(routes.AccessCode.path + 'company');
    }
  }, [status, errorContent, navigate]);

  const data = convertNameCompanyAccessCodeData(individualsCompanyDetail);

  useEffect(() => {
    form.setFieldsValue(individualsCompanyDetail);
    form.resetFields();
  }, [form, individualsCompanyDetail]);

  const onFinish = async (values: any) => {
    try {
      if (individualsCompanyDetail) {
        const result = {
          companyName: values.companyName,
          companyUsersFile: values.companyUsersFile,
          fileName: values.fileName,
        };

        setIsLoading(true);
        const response = await accessCodeApi.editAccessCodeForCompanyIndividual(
          individualsCompanyDetail.id,
          result
        );
        if (response.data.success) {
          setStatus('success');
        }
      }
    } catch (err) {
      const errors = err as Error | AxiosError;
      if (!axios.isAxiosError(errors)) {
        setErrorContent(errors.message);
      }

      const axiosErr = errors as AxiosError;
      setErrorContent(axiosErr.response?.data.message);

      setStatus('failed');
    } finally {
      setIsLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {};

  return (
    <div>
      {individualsCompanyDetail && (
        <Form
          form={form}
          layout="vertical"
          style={{ maxWidth: '100%' }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className={`accessIndividual flex flex-col gap-6 `}
          initialValues={{
            ...data,
          }}
        >
          <div className={` w-full h-fit flex flex-rows justify-between overflow-auto`}>
            <div className={`flex flex-row justify-center items-center gap-8`}>
              <LeftOutlined
                className={`text-primary text-[22px]`}
                onClick={() => {
                  navigate(routes.AccessCode.path + 'company');
                }}
              />
              <div className={`text-3xl font-bold text-primary`}>
                Individuals of a specific company
              </div>
            </div>

            <Form.Item shouldUpdate>
              {() => (
                <Button
                  type="primary"
                  htmlType="submit"
                  className={`w-80 h-fit p-3 rounded-md 
                  text-[16px] font-bold text-white
                  bg-primary border-0
                  cursor-pointer disabled:bg-[#a6a2bc]
                  disabled:hover:bg-[#a6a2bc] disabled:hover:text-white
                  disabled:text-white disabled:border-none disabled:cursor-not-allowed`}
                  disabled={
                    isLoading ||
                    !!form.getFieldsError().filter(({ errors }) => errors.length).length ||
                    form.getFieldValue('companyUsersFile') === undefined ||
                    form.getFieldValue('companyName') === ''
                  }
                >
                  {!isLoading && <>Save</>}
                  {isLoading && <>Saving...</>}
                </Button>
              )}
            </Form.Item>
          </div>

          <div className={`content flex flex-col justify-center gap-4`}>
            <Form.Item
              label={'1. Name of company'}
              name={'companyName'}
              rules={[
                {
                  required: true,
                  message: 'Enter the name of company here',
                },
              ]}
            >
              <Input
                placeholder="Enter the name of the company"
                maxLength={50}
                showCount
                disabled={isLoading}
              />
            </Form.Item>
            <Form.Item
              name={'companyUsersFile'}
              label="2. List of Individuals"
              rules={[
                {
                  required: true,
                  message: 'Please upload file',
                },
              ]}
            >
              <UploadFileForm
                fileFieldValueName="fileName"
                fileFieldName="companyUsersFile"
                defaultFile={individualsCompanyDetail.companyUsersFile}
                defaultFileName={individualsCompanyDetail.fileName}
                form={form}
              />
            </Form.Item>

            <Form.Item
              name="fileName"
              style={{
                height: '0px',
              }}
            ></Form.Item>
          </div>
        </Form>
      )}
    </div>
  );
};

export default EditAccessForIndividualsOfCompany;
