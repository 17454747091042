
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserProfileState } from "./userProfile.state";

const initialState: UserProfileState = {
   currentPage:1,
};

const userProfileReducer = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<UserProfileState>){
      state.currentPage = action.payload.currentPage
    },
  }
});

export const {  setCurrentPage } = userProfileReducer.actions;
export default userProfileReducer.reducer;