import { LeftOutlined } from '@ant-design/icons';
import ArrowLeft from '@assets/images/icons/arrow-left.svg';
import routes from '@routes';
import { AccessPermission, accessPerMissionType } from '@utils/accesspermissionType';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AccessCode.scss';

interface AddAccessCodeButtonProp {
  onClick: () => void;
  access: AccessPermission;
}

const AddAccessCodeButton = ({ access, onClick }: AddAccessCodeButtonProp) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <div
      className={`max-w-[420px] max-h-[250px] w-[32rem] h-80 p-2
      flex flex-col justify-center items-center gap-4 } 
      text-white  text-xl font-semibold text-center
      rounded-xl cursor-pointer 
      ${
        !isHover
          ? ''
          : `
          translate-x-[-5px]
          translate-y-[-5px]`
      }
      ease-in-out
      duration-300`}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={onClick}
      style={{
        backgroundColor: access.color,
        boxShadow: isHover ? '5px 5px 0px 0px #4D4479' : 'none',
      }}
    >
      <img src={access.icon} alt={access.title} />
      {access.title}
      <img src={ArrowLeft} alt={'arrow-left'} />
    </div>
  );
};

const AddAccessCode = () => {
  const navigate = useNavigate();

  return (
    <div className={`w-full h-full flex flex-col gap-4 p-[40px]`}>
      <div className={`flex flex-row justify-left items-center gap-8`}>
        <LeftOutlined
          className={`text-primary text-[22px]`}
          onClick={() => {
            navigate(routes.AccessCode.path);
          }}
        />
        <div className={`text-3xl font-bold text-primary`}>Add access permission</div>
      </div>
      <div className={` w-full h-full flex justify-center items-center`}>
        <div className={`grid grid-cols-2 grid-rows-2 place-content-center gap-12`}>
          {accessPerMissionType.map((access) => {
            return (
              <AddAccessCodeButton
                access={access}
                onClick={() => {
                  navigate(access.path);
                }}
                key={access.path}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AddAccessCode;
