import { createAsyncThunk } from "@reduxjs/toolkit";
import accountApi from "../../apis/account.api";
import AccountLogin from "../../models/AccountLogin.model";

enum AuthTypesEnum {
  SignInAsync = "@Auth/signInAsync",
  SignOutAsync = "@Auth/signOutAsync",
}

export const signInAsync = createAsyncThunk(
  AuthTypesEnum.SignInAsync,
  async (params: AccountLogin) => {
    const response = await accountApi.login(params);
    return response.data.data;
  }
);

export const signOutAsync = createAsyncThunk(AuthTypesEnum.SignOutAsync, async () => {
  await accountApi.logout();
})
