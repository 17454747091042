import SmileIcon from '@assets/images/icons/smile-icon.svg';
import ChatSendIcon from '@assets/images/icons/chat-send-icon.svg';
import ChatReplyIcon from '@assets/images/icons/chat-reply-icon.svg';
import ChatCloseIcon from '@assets/images/icons/chat-close-icon.svg';
import EmojiPicker from 'emoji-picker-react';
import { useEffect, useRef, useState } from 'react';
import { addDoc, collection, doc, updateDoc, getDoc, Timestamp, setDoc } from 'firebase/firestore';
import { db } from '../HandleAccessFirebase';
import { Rate } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '@state/rootReducer';
import { store } from '@state/store';
import { resetReply } from '@state/chat/replyChatReducer';
import privateChatApi from '@apis/privateChat.api';

interface ChatTextAreaProp {
  chatId: string;
  currentUserId: string;
}
const ChatTextArea = ({ chatId, currentUserId }: ChatTextAreaProp) => {
  const [inputValue, setInputValue] = useState('');
  const [showPicker, setShowPicker] = useState(false);
  const replyDetail = useSelector((state: RootState) => state.replyChat);
  const pickerRef = useRef<HTMLDivElement>(null);
  const handleEmojiClick = (emoji: any) => {
    setInputValue(inputValue + emoji.emoji);
    setShowPicker(false);
  };
  const handleSendMessage = async () => {
    if (inputValue === '' || inputValue.trim() === '') {
      return;
    }
    store.dispatch(resetReply());
    setInputValue('');
    const anaPrivateChat = collection(db, `ana-private-chat/private-chat/chats/${chatId}/messages`);
    const docRef = doc(db, 'ana-private-chat/private-chat/sessions', chatId);
    const getCurrentAdminId = localStorage.getItem('currentUserId');
    if (replyDetail.text) {
      if (replyDetail.rating) {
        const response = await addDoc(anaPrivateChat, {
          timestamp: Timestamp.now(),
          content: { text: replyDetail.text, replyText: inputValue, rating: replyDetail.rating },
          messageType: 'reply',
          sendByAdmin: true,
          senderId: getCurrentAdminId,
          unread: false,
        });
        if (response.id) {
          const payload = {
            clinicId: getCurrentAdminId,
            chatId: chatId,
            replyId: response.id,
            reply: inputValue,
          };
          try {
            await privateChatApi.replyMessage(replyDetail.messageId, payload);
          } catch (error) {}
        }
      } else {
        const response = await addDoc(anaPrivateChat, {
          timestamp: Timestamp.now(),
          content: { text: replyDetail.text, replyText: inputValue },
          messageType: 'reply',
          senderId: getCurrentAdminId,
          sendByAdmin: true,
          unread: false,
        });
        if (response.id) {
          const payload = {
            clinicId: getCurrentAdminId,
            chatId: chatId,
            replyId: response.id,
            reply: inputValue,
          };
          await privateChatApi.replyMessage(replyDetail.messageId, payload);
        }
      }
    } else {
      await addDoc(anaPrivateChat, {
        timestamp: Timestamp.now(),
        content: { text: inputValue },
        messageType: 'text',
        senderId: getCurrentAdminId,
        sendByAdmin: true,
        unread: false,
      });
    }
    const payload = {
      recipientUserId: currentUserId,
    };

    const docSnap = await getDoc(docRef);
    if (!docSnap.data()) {
      await setDoc(doc(db, `ana-private-chat/private-chat/sessions`, chatId), {
        timestamp: Timestamp.now(),
        lastMessage: inputValue,
        userUnreadCount: docSnap.data()?.userUnreadCount ? docSnap.data()?.userUnreadCount + 1 : 1,
      });
    } else {
      await updateDoc(docRef, {
        timestamp: Timestamp.now(),
        lastMessage: inputValue,
        userUnreadCount: docSnap.data()?.userUnreadCount ? docSnap.data()?.userUnreadCount + 1 : 1,
      });
    }
    await privateChatApi.sendNotification(payload);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey && !event.altKey) {
      event.preventDefault(); // Prevents the default behavior of adding a new line
      handleSendMessage();
    }
  };

  const handleDeleteReply = () => {
    store.dispatch(resetReply());
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target as Node)) {
        setShowPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [pickerRef]);
  return (
    <div
      className={`${
        replyDetail.text && replyDetail.chatId === chatId ? 'h-fit' : 'h-[36%]'
      }  p-[20px]`}
      onSubmit={() => handleSendMessage()}
    >
      {replyDetail.text && replyDetail.chatId === chatId ? (
        <div
          className={`${
            replyDetail.text && replyDetail.chatId === chatId ? 'h-fit' : 'h-[67%]'
          } rounded-xl w-full bg-[#F5F5F5] border-[#DADADA] text-[#1E1E1E] p-2  mt-[10px]  h-fit `}
        >
          {replyDetail.text && replyDetail.chatId === chatId && (
            <div>
              <div className="p-[10px] w-full h-fit flex flex-row justify-between reply-chat-container ">
                <div className=" w-fit flex flex-row h-fit">
                  <img
                    alt=""
                    className="w-[32px] h-[32px] mr-[10px] mt-[10px] "
                    src={ChatReplyIcon}
                  />
                  <div className="max-w-[600px]">
                    <p className={`w-full p-[10px] rounded-xl bg-primary text-[white] `}>
                      <p className="m-0 line-clamp-2">{replyDetail.text}</p>
                      {replyDetail.rating && (
                        <div className="flex justify-end ">
                          <Rate
                            className="pt-[3px]  pl-[16px] mt-[4px] bg-[#FFFFFF1A] w-[180px]  rounded-xl  h-[40px] rating-container"
                            disabled
                            value={replyDetail.rating ? replyDetail.rating : 0}
                          />
                        </div>
                      )}
                    </p>
                  </div>
                </div>
                <div>
                  <img
                    alt=""
                    className="w-[32px] h-[32px] cursor-pointer"
                    src={ChatCloseIcon}
                    onClick={() => handleDeleteReply()}
                  />
                </div>
              </div>
              <textarea
                onKeyDown={handleKeyDown}
                placeholder="Type a message ..."
                className="resize-none border-none bg-[#F5F5F5] focus:!border-none focus:!outline-none w-full "
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              ></textarea>
            </div>
          )}
        </div>
      ) : (
        <textarea
          onKeyDown={handleKeyDown}
          className=" rounded-xl w-full bg-[#F5F5F5] border-[#DADADA] text-[#1E1E1E] p-2 h-[67%] mt-[10px] resize-none "
          placeholder="Type a message ..."
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        ></textarea>
      )}

      <div
        className={`flex flex-row justify-between w-full h-[33%] ${
          replyDetail.text && replyDetail.chatId === chatId ? 'pt-[4px] pb-[4px]' : ''
        } `}
      >
        <div className="relative  ">
          <img
            alt=""
            className="cursor-pointer w-[32px] h-[32px]"
            src={SmileIcon}
            onClick={() => setShowPicker(!showPicker)}
          />
          <div className="absolute top-[-450px]" ref={pickerRef}>
            <EmojiPicker open={showPicker} onEmojiClick={handleEmojiClick} />
          </div>
        </div>
        <button
          className="w-[160px] h-[56px] rounded-xl border-none bg-[#766BA8] font-[16px] font-bold text-white cursor-pointer"
          onClick={() => handleSendMessage()}
        >
          Send <img alt="" className="w-[20px] h-[20px] ml-[4px] " src={ChatSendIcon} />
        </button>
      </div>
    </div>
  );
};
export default ChatTextArea;
