import EditIcon from '@assets/images/icons/editIcon.svg';

const EditBtn = ({
  openEdit,
}: {
  openEdit: () => void; //some argument but later
}) => {
  return (
    <>
      <img
        src={EditIcon}
        alt="edit-module"
        onClick={openEdit}
        className={`w-[40px] h-[40px] hover:cursor-pointer`}
      />
    </>
  );
};
export default EditBtn;
