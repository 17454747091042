
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AnaProgramState } from "./anaProgram.state";


const initialState: AnaProgramState = {
   isExpandFull: false,
   isQuicklyScreen: false,
   userId:'',
   chatId:'',
   subscriptionExpired:false,
   currentPage:1,
};

const anaProgramReducer = createSlice({
  name: 'anaProgram',
  initialState,
  reducers: {
    setExpandFull(state, action: PayloadAction<AnaProgramState>) {
      state.isExpandFull =  action.payload.isExpandFull
    },
    removeExpandFull(state) {
        state.isExpandFull =  false
    },
    setQuicklyScreen(state, action: PayloadAction<AnaProgramState>) {
        state.isQuicklyScreen = action.payload.isQuicklyScreen
    },
    addUserId(state, action: PayloadAction<AnaProgramState>){
        state.userId = action.payload.userId
    },
    addChatId(state, action: PayloadAction<AnaProgramState>){
      state.chatId = action.payload.chatId
    }, 
    setCurrentPage(state, action: PayloadAction<AnaProgramState>){
      state.currentPage = action.payload.currentPage
    },
    setSubscriptionExpired(state, action: PayloadAction<AnaProgramState>){
      state.subscriptionExpired = action.payload.subscriptionExpired
    }
  }
});

export const { setExpandFull, removeExpandFull, setQuicklyScreen, addUserId,addChatId, setCurrentPage,setSubscriptionExpired } = anaProgramReducer.actions;
export default anaProgramReducer.reducer;