import NoMatch from '@components/NoMatch';

const ListOrderPage = () => {
  // const navigate = useNavigate();
  // const transferDate = (date: string) => {
  //   const transferDate = new Date(date);
  //   const dateString = transferDate.toISOString().split('T')[0];
  //   return dateString;
  // };

  // const getTagColor = (tagName: string) => {
  //   let color = '';
  //   if (tagName === 'Approved' || tagName === 'Complete') {
  //     color = 'green';
  //   }
  //   if (tagName === 'Rejected') {
  //     color = 'volcano';
  //   }
  //   if (tagName === 'Pending' || tagName === 'Incomplete') {
  //     color = 'yellow';
  //   }
  //   return color;
  // };
  // const columns: ColumnsType<OrderType> = [
  //   {
  //     title: 'Id',
  //     dataIndex: 'id',
  //     className: 'column-first-item',
  //     key: 'id',
  //     render: (text) => <p>{orderData.indexOf(text)}</p>,
  //   },
  //   {
  //     title: 'User Name',
  //     dataIndex: 'userName',
  //     key: 'userName',
  //   },
  //   {
  //     title: 'Request date',
  //     dataIndex: 'requestDate',
  //     key: 'requestDate',
  //     render: (date) => {
  //       const dateString = transferDate(date);
  //       return <div>{dateString}</div>;
  //     },
  //   },
  //   {
  //     title: 'Currency',
  //     key: 'currency',
  //     dataIndex: 'currency',
  //   },
  //   {
  //     title: 'Authorizing provider',
  //     key: 'authorizingProvider',
  //     dataIndex: 'authorizingProvider',
  //   },
  //   {
  //     title: 'Authorizing date',
  //     key: 'authorizingDate',
  //     dataIndex: 'authorizingDate',
  //     render: (date) => {
  //       const dateString = transferDate(date);
  //       return <div>{dateString}</div>;
  //     },
  //   },
  //   {
  //     title: 'Request status',
  //     key: 'requestStatus',
  //     dataIndex: 'requestStatus',
  //     render: (data) => {
  //       const color = getTagColor(data);
  //       return (
  //         <Tag
  //           className="rounded-2xl w-[88px] flex justify-center h-[21px] text-[14px]"
  //           color={color}
  //           key={data}
  //         >
  //           {data}
  //         </Tag>
  //       );
  //     },
  //   },
  //   {
  //     title: 'Order status',
  //     key: 'orderStatus',
  //     dataIndex: 'orderStatus',
  //     render: (data) => {
  //       const color = getTagColor(data);
  //       return (
  //         <Tag
  //           className="rounded-2xl w-[99px] flex justify-center h-[21px] text-[14px]"
  //           color={color}
  //           key={data}
  //         >
  //           {data}
  //         </Tag>
  //       );
  //     },
  //   },
  //   {
  //     title: 'View',
  //     dataIndex: 'userName',
  //     className: 'column-last-item',
  //     key: 'action',
  //     render: (index) => {
  //       return (
  //         <p
  //           className=" text-[16px] text-[#2F6BFF] underline  decoration-solid hover:text-[#5384ff]"
  //           onClick={() => {
  //             navigate(`/user-profile/detail/${index}/order-detail`);
  //           }}
  //         >
  //           View {index}
  //         </p>
  //       );
  //     },
  //   },
  // ];
  return (
    <div className="p-[40px]">
      <NoMatch
        title=""
        content="Coming soon"
        description=""
        pathName=""
        className={` w-full flex flex-col items-center  h-fit justify-center space-y-5 m-auto p-[120px]`}
      />
      {/* <div className="flex flex-row justify-between w-full h-full">
        <p className="text-3xl text-primary font-bold w-[300px] mt-[10px]">List of order</p>
        <SearchButton onClick={() => {}} />
      </div>
      <div className="mt-[10px]">
        <Table className="table-user-profile " columns={columns} dataSource={orderData} />
      </div> */}
    </div>
  );
};
export default ListOrderPage;
