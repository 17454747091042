import { store } from '@state/store';
import {
  addChatId,
  addUserId,
  setCurrentPage,
  setExpandFull,
  setQuicklyScreen,
  setSubscriptionExpired,
} from '../ratingReducer';

export const handleSetIsExpandFull = (isExpandFull: boolean) => {
  const payload = {
    isExpandFull: isExpandFull,
    isQuicklyScreen: false,
    userId: '',
    chatId: '',
    subscriptionExpired: false,
    currentPage: 1,
  };
  store.dispatch(setExpandFull(payload));
};

export const handleSetIsQuicklyScreen = (isQuicklyScreen: boolean) => {
  const payload = {
    isExpandFull: false,
    isQuicklyScreen: isQuicklyScreen,
    userId: '',
    chatId: '',
    subscriptionExpired: false,
    currentPage: 1,
  };
  store.dispatch(setQuicklyScreen(payload));
};

export const handleAddUserId = (userId: string) => {
  const payload = {
    isExpandFull: false,
    isQuicklyScreen: false,
    userId: userId,
    chatId: '',
    subscriptionExpired: false,
    currentPage: 1,
  };
  store.dispatch(addUserId(payload));
};

export const handleAddChatId = (chatId: string) => {
  const payload = {
    isExpandFull: false,
    isQuicklyScreen: false,
    userId: '',
    chatId: chatId,
    subscriptionExpired: false,
    currentPage: 1,
  };
  store.dispatch(addChatId(payload));
};

export const handleSetCurrentPage = (page: number) => {
  const payload = {
    isExpandFull: false,
    isQuicklyScreen: false,
    userId: '',
    chatId: '',
    subscriptionExpired: false,
    currentPage: page,
  };
  store.dispatch(setCurrentPage(payload));
};

export const handleSetSubscriptionExpired = (subscriptionExpired: boolean) => {
  const payload = {
    isExpandFull: false,
    isQuicklyScreen: false,
    userId: '',
    chatId: '',
    subscriptionExpired: subscriptionExpired,
    currentPage: 1,
  };
  store.dispatch(setSubscriptionExpired(payload));
};
