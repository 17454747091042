export interface MenuItem {
  title: string;
  path?: string;
  MenuIcon?: React.ReactNode;
  children?: MenuItem[];
  key: string;
}

export function findMenuItemIndex(location: string, menuItems: MenuItem[]): string | number {
  const findIndexRecursive = (
    menuItems: MenuItem[],
    path: string,
    parentIndex: string = ''
  ): string | number | null => {
    for (let i = 0; i < menuItems.length; i++) {
      const menuItem = menuItems[i];

      if (path.includes(menuItem.path as string)) {
        return parentIndex !== '' ? `${parentIndex}.${i}` : `${i}`;
      }

      if (menuItem.children) {
        const childIndex = findIndexRecursive(
          menuItem.children,
          path,
          parentIndex !== '' ? `${parentIndex}.${i + 1}` : `${i}`
        );
        if (childIndex) {
          return childIndex;
        }
      }
    }

    return null;
  };

  const menuItemIndex = findIndexRecursive(menuItems, location);
  return menuItemIndex !== null ? menuItemIndex : -1;
}
