import { Pagination, SelectProps, Skeleton } from 'antd';
import { memo, useCallback, useEffect, useState } from 'react';
import affirmationApi from '../../apis/affirmation.api';
import NoMatch from '../../components/NoMatch';
import Affirmation from '../../models/Affirmation.model';
import { reasons } from '../../utils/reasons.util';
import './Affirmation.style.scss';
import AffirmationCard from './component/AffirmationCard.page';
import CreateAffirmationModal from './component/CreateAffirmationModal.page';

import PageHeader from '../../components/PageHeader';

const initPagination = {
  page: 1,
  limit: 6,
};
const AffirmationPage = () => {
  const [affirmations, setAffirmations] = useState<Affirmation[]>([]);
  const [pagination, setPagination] = useState(initPagination);
  const [totalAffirmation, setTotalAffirmation] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [current, setCurrent] = useState(1);
  const [loadingData, setLoadingData] = useState(false);
  const [selectedTag, setSelectedTag] = useState<string | null>(null);
  const [filteredAffirmations, setFilteredAffirmations] = useState<Affirmation[]>([]);

  const handleChange = (value: string | null) => {
    setSelectedTag(value);
    setPagination({ ...pagination, page: 1 });
    setCurrent(1);
  };

  // const options = Array.from(new Set(affirmations.flatMap((a) => a.tags)));
  const sortedReasons = reasons.sort((a, b) => a.label.localeCompare(b.label));
  const options: SelectProps['options'] = sortedReasons;

  const getAffirmations = useCallback(async () => {
    try {
      setLoadingData(true);
      const response = await affirmationApi.getAffirmations(pagination.page, pagination.limit);
      if (response.data.success) {
        setAffirmations(response.data.data.affirmations);
        setTotalAffirmation(response.data.data.totalCount);
        filterAffirmations();
      }
    } catch (error) {
      console.error(error);
      setLoadingData(false);
    } finally {
      setLoadingData(false);
    }
  }, [pagination]);

  const filterAffirmations = useCallback(async () => {
    try {
      setLoadingData(true);
      const response = await affirmationApi.filterAffirmations(
        pagination.page,
        pagination.limit,
        selectedTag ? selectedTag : ''
      );
      if (response.data.success) {
        setFilteredAffirmations(response.data.data.affirmations);
        setTotalAffirmation(response.data.data.totalCount);
      }
    } catch (error) {
      console.error(error);
      setLoadingData(false);
    } finally {
      setLoadingData(false);
    }
  }, [selectedTag, pagination]);

  useEffect(() => {
    filterAffirmations();
  }, [selectedTag, pagination]);

  useEffect(() => {
    getAffirmations();
  }, [pagination, getAffirmations]);

  const onChange = (page: number, pageSize: number) => {
    setPagination({ page: page, limit: pageSize });
    setCurrent(page);
  };

  const addNewAffirmation = () => {
    setIsModalOpen(true);
  };

  const onCancelCreate = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className={`affirmation-page flex flex-col h-full p-[40px]`}>
        <PageHeader
          addNewFunction={addNewAffirmation}
          handleChange={handleChange}
          options={options}
          title="Add new"
          filterPlaceholder="Reasons"
        />
        <div className={`pr-4 overflow-auto`}>
          <div className={`grid grid-cols-3 gap-x-6 gap-y-8 pt-7 pb-8`}>
            {affirmations.length === 0 ? (
              loadingData ? (
                <Skeleton active avatar title paragraph={{ rows: 3, width: '100%' }} />
              ) : (
                !affirmations && (
                  <NoMatch
                    pathName=""
                    className={`absolute w-fit flex flex-col items-center  h-fit justify-center space-y-5 m-auto p-[120px]`}
                    title="No affirmation was uploaded"
                    description=""
                  />
                )
              )
            ) : filteredAffirmations.length === 0 ? (
              <>
                <NoMatch
                  pathName=""
                  className={`absolute left-1/2 w-fit font-semibold text-xl 
                  translate-x-[-50%] flex flex-col items-center  
                  h-fit justify-center space-y-5 m-auto p-[120px]`}
                  title="No affirmation match your search"
                  description=""
                />
              </>
            ) : (
              filteredAffirmations.map((affirmation) => {
                return (
                  <div key={affirmation.id}>
                    {loadingData ? (
                      <Skeleton active title paragraph={{ rows: 3, width: '100%' }} />
                    ) : (
                      <AffirmationCard
                        onEditOk={() => getAffirmations()}
                        onDeleteOk={() => getAffirmations()}
                        key={affirmation.id}
                        affirmation={affirmation}
                      />
                    )}
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div className={`page-pagination flex justify-center pt-4`}>
          {filteredAffirmations.length > 0 && (
            <Pagination
              current={current}
              total={totalAffirmation}
              defaultPageSize={initPagination.limit}
              onChange={onChange}
            />
          )}
        </div>
      </div>

      {isModalOpen && (
        <CreateAffirmationModal
          onModalOk={() => getAffirmations()}
          onClose={onCancelCreate}
          onOpen={isModalOpen}
          model={'add'}
        />
      )}
    </>
  );
};

export default memo(AffirmationPage);
