import UserInformationPrivateChat from '@components/UserInformationPrivateChat';
import ChatTextArea from '@pages/private-chat/privateChatSubscription/ChatTextArea';
import ViewChatTextArea from '@pages/private-chat/privateChatSubscription/ViewChatTextArea';
import { RootState } from '@state/rootReducer';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './UserRating.styles.scss';
import SearchButton from '@components/SearchButton';
import { message, Rate, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  handleSetLimitList,
  handleSetCurrentChatItem,
  handleSetFirstLoading,
  handleSetLimit,
  handleSetSubscriptionExpired,
} from '@state/chat/handleSetStateChat/handleSetStateChat';
import {
  handleAddChatId,
  handleSetCurrentPage,
  handleSetIsExpandFull,
  handleSetIsQuicklyScreen,
} from '@state/rating/handleSetStateRating/handleSetStateRating';
import { store } from '@state/store';
import { addUserProfile, resetUserProfile } from '@state/user/userReducer';
import { collection, doc, endAt, getDoc, getDocs, orderBy, query, where } from 'firebase/firestore';
import { db } from '@pages/private-chat/HandleAccessFirebase';
import privateChatApi from '@apis/privateChat.api';
import RatingMessageModel from '@models/RatingMessage.model';
import { addReply, resetReply } from '@state/chat/replyChatReducer';
import ArrowRight from '@assets/images/icons/arrow-right.svg';
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { handleGetSortDirection } from '@components/SortList';

export const pagination = {
  pageNumber: 1,
  pageSize: 8,
};
const UserRating = () => {
  const [isClick, setIsClick] = useState<boolean>(
    useSelector((state: RootState) => state.ratingList.isQuicklyScreen)
  );
  const getUserChat = useSelector((state: RootState) => state.userChat);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChatLoading, setIsChatLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(pagination.pageNumber);
  const [ratingMessageList, setRatingMessageList] = useState<RatingMessageModel[]>([]);
  const [isNavigating, setIsNavigating] = useState<boolean>(false);
  const [totalEmails, setTotalEmails] = useState<number>(0);
  const currentChatItem = useSelector((state: RootState) => state.ratingList.chatId);
  const getAuthUser = localStorage.getItem('currentUserId');
  const handleGetUserDetail = async (userId: string, userName: string, userEmail: string) => {
    try {
      const payload = {
        id: userId,
        userName: userName,
        email: userEmail,
      };
      store.dispatch(addUserProfile(payload));
      setIsChatLoading(false);
    } catch (error) {
      message.error('Something error');
    }
  };

  const handleGetMessage = async (chatId: string, messageId: string) => {
    const getReplyChatId = doc(
      collection(db, `ana-private-chat/private-chat/chats/${chatId}/messages`),
      messageId
    );
    const getDetail = doc(collection(db, `ana-private-chat/private-chat/sessions`), chatId);
    const getSubscriptionDetail = await getDoc(getDetail);
    handleSetSubscriptionExpired(getSubscriptionDetail.data()?.subscriptionExpired ?? false);
    const getDocDetail = await getDoc(getReplyChatId);
    const q = query(
      collection(db, `ana-private-chat/private-chat/chats/${chatId}/messages`),
      orderBy('timestamp', 'desc'),
      endAt(getDocDetail)
    );
    const getListDetail = await getDocs(q);
    if (getListDetail.docs.length > 0) {
      handleSetLimit(getListDetail.docs.length >= 15 ? getListDetail.docs.length : 15);
    }
  };

  const handleSetReplyMessage = (
    text: string,
    chatId: string,
    messageId: string,
    rating: number
  ) => {
    const payload = {
      senderId: getAuthUser ? getAuthUser : '',
      text: text,
      chatId: chatId,
      messageId: messageId,
      rating: rating,
    };
    store.dispatch(addReply(payload));
  };

  const handleGetListRating = async (pageNumber: number) => {
    try {
      setIsLoading(true);
      const listRatingResponse = await privateChatApi.getRatingList(
        pageNumber,
        pagination.pageSize
      );
      if (listRatingResponse.status === 200) {
        setRatingMessageList(listRatingResponse.data.data.ratingMessages);
        setTotalEmails(listRatingResponse.data.data.totalCount);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleSortListRating = async (sortType: string | undefined, pageNumber: number) => {
    try {
      const sortDirection = handleGetSortDirection(sortType);
      const listRatingResponse = await privateChatApi.sortRatingList(
        pageNumber,
        pagination.pageSize,
        sortDirection ? 'Rating' : undefined,
        sortDirection
      );
      if (listRatingResponse.status === 200) {
        setRatingMessageList(listRatingResponse.data.data.ratingMessages);
        setTotalEmails(listRatingResponse.data.data.totalCount);
      }
      setIsLoading(false);
      return ratingMessageList;
    } catch (error) {
      message.error('something wrong');
    }
  };

  const handleSearch = async (value: string) => {
    const searchResponse = await privateChatApi.searchRatingList(1, 10, value);
    if (searchResponse.status === 200) {
      setRatingMessageList(searchResponse.data.data.ratingMessages);
      setTotalEmails(searchResponse.data.data.totalCount);
    }
  };
  useEffect(() => {
    handleGetListRating(1);
  }, []);
  const navigate = useNavigate();

  const columns: ColumnsType<RatingMessageModel> = [
    {
      title: 'No.',
      className: 'column-first-item',
      key: 'id',
      render: (ratingMessage: RatingMessageModel) =>
        page * pagination.pageSize -
        pagination.pageSize +
        ratingMessageList.indexOf(ratingMessage) +
        1,
    },
    {
      title: 'Rating date',
      dataIndex: 'ratingDate',
      key: 'ratingDate',
      render: (ratingDate) => moment(ratingDate).format('DD/MM/YYYY'),
    },
    {
      title: 'Clinician name',
      dataIndex: 'clinicName',
      key: 'clinicName',
    },
    {
      title: 'User name',
      key: 'username',
      dataIndex: 'username',
    },
    {
      title: 'Message',
      dataIndex: 'message',
      render: (message) => {
        return <p className="line-clamp-2 p-0 m-0 w-[336px]">{message}</p>;
      },
    },
    {
      title: 'Replied',
      dataIndex: 'reply',
      render: (reply) => {
        return <p className="line-clamp-2  p-0 m-0 w-[336px]">{reply}</p>;
      },
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      render: (index) => {
        return <Rate disabled value={index} />;
      },
      sorter: true,
    },
    {
      title: 'Detail',
      className: 'column-last-item',
      key: 'action',
      render: (ratingMessage: RatingMessageModel) => {
        return (
          <p
            className="cursor-pointer text-[16px] text-[#2F6BFF] underline  decoration-solid hover:text-[#5384ff] m-0 p-0"
            onClick={() => {
              setIsChatLoading(true);
              handleGetMessage(ratingMessage.chatId, ratingMessage.messageId);
              setIsActive(true);
              setIsClick(true);
              handleSetIsQuicklyScreen(true);
              handleSetCurrentChatItem(ratingMessage.chatId);
              handleAddChatId(ratingMessage.chatId);
              handleSetReplyMessage(
                ratingMessage.message,
                ratingMessage.chatId,
                ratingMessage.messageId,
                ratingMessage.rating
              );
              handleGetUserDetail(
                ratingMessage.chatId.split('session-user-')[1],
                ratingMessage.username,
                ratingMessage.userEmail
              );
            }}
          >
            Reply
          </p>
        );
      },
    },
  ];
  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<RatingMessageModel> | SorterResult<RatingMessageModel>[],
    extra: TableCurrentDataSource<RatingMessageModel>
  ) => {
    const isSorter = Array.isArray(sorter) ? sorter[0].column : sorter.column;
    setPage(pagination.current ? pagination.current : 1);
    if (isSorter?.title) {
      setIsLoading(true);
      const sortOrder = Array.isArray(sorter) ? sorter[0].order : sorter.order;
      handleSortListRating(
        sortOrder ? sortOrder : undefined,
        pagination.current ? pagination.current : 1
      );
    } else {
      handleGetListRating(pagination.current ? pagination.current : 1);
      handleSetCurrentPage(page);
    }
  };
  const handleChangeList = async () => {
    setIsNavigating(true);
    const getDetail = doc(
      collection(db, `ana-private-chat/private-chat/sessions`),
      currentChatItem
    );
    const getDocDetail = await getDoc(getDetail);

    const q = query(
      collection(db, `ana-private-chat/private-chat/sessions`),
      where('subscriptionExpired', '==', true),
      orderBy('timestamp', 'desc'),
      endAt(getDocDetail)
    );
    const getListDetail = await getDocs(q);
    if (getListDetail.docs.length > 0) {
      handleSetLimitList(getListDetail.docs.length >= 8 ? getListDetail.docs.length : 8);
    }
    handleSetIsExpandFull(true);
    handleSetFirstLoading(false);
    handleSetCurrentChatItem(currentChatItem);
    setIsNavigating(false);
    navigate('/privateChat');
  };
  return (
    <div className="relative h-full">
      <div className={`p-[40px] relative  h-full`}>
        <div className=" flex flex-row justify-between ">
          <p className="text-3xl text-primary font-bold w-[300px] mt-[24px]">Rating List</p>
          <div className="w-[396px]">
            <SearchButton onClick={handleSearch} isRatingPage={true} />
          </div>
        </div>

        <div>
          <Table
            className="table-user-profile "
            loading={isLoading}
            columns={columns}
            dataSource={ratingMessageList}
            onChange={handleTableChange}
            pagination={{
              position: ['bottomCenter'],
              pageSize: pagination.pageSize,
              total: totalEmails,
              pageSizeOptions: [pagination.pageSize, 20, 40],
              defaultCurrent: page,
            }}
          />
        </div>
      </div>
      {isClick && (
        <div
          className={`absolute top-0 right-0 z-40 bg-white w-[600px] h-full shadow-lg  ${
            isActive ? 'slide-left-rating' : 'slide-right-rating'
          }`}
        >
          <div className={`w-full h-full  flex flex-col`}>
            <div className="view-detail-chat-header h-[146px] w-full flex flex-row justify-between">
              <div className=" w-[95%]">
                <UserInformationPrivateChat
                  userName={getUserChat.userName}
                  body={getUserChat.email}
                  isClick={false}
                ></UserInformationPrivateChat>
              </div>
              <div className={` pt-[40px] pr-[12px]  cursor-pointer`}>
                <img
                  alt=""
                  src={ArrowRight}
                  onClick={() => {
                    handleSetIsQuicklyScreen(false);
                    setIsActive(false);
                    setTimeout(() => {
                      setIsClick(false);
                      store.dispatch(resetUserProfile());
                      handleSetFirstLoading(true);
                      store.dispatch(resetReply());
                    }, 998);
                  }}
                />
              </div>
            </div>
            <div className="flex justify-center bg-[#E2EBFF] ">
              <p
                className="p-[4px] m-0 text-[16px] cursor-pointer text-[#2F6BFF] underline"
                onClick={() => {
                  handleChangeList();
                }}
              >
                To full chat screen {isNavigating && <Spin className="ml-[10px]" size="small" />}
              </p>
            </div>

            <ViewChatTextArea
              loadingDetailChat={isChatLoading}
              finishLoad={() => {}}
              currentChatItem={currentChatItem}
            ></ViewChatTextArea>

            <ChatTextArea
              chatId={currentChatItem ? currentChatItem : ''}
              currentUserId={getUserChat.id}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default UserRating;
