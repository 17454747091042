import { Rate } from 'antd';
import React from 'react';

interface ChatWithRatingProp {
  content: string;
  rating: number;
  senderByAdmin: boolean;
  isReply: boolean;
  messageTypeReply: boolean;
}

const ChatWithRating = ({
  content,
  rating,
  isReply,
  senderByAdmin,
  messageTypeReply,
}: ChatWithRatingProp) => {
  return (
    <div className={`max-w-[530px] w-full font-[16px]  flex flex-col `}>
      <div
        className={`w-full  flex ${
          !senderByAdmin || messageTypeReply ? 'justify-start' : 'justify-end'
        }`}
      >
        <p
          className={`w-fit max-w-[530px] break-words p-[10px] rounded-2xl ${
            isReply ? '' : 'rounded-br-none'
          } mb-[8px] ${
            !senderByAdmin
              ? 'bg-[#F0F0F0] text-[#000000] rounded-bl-none !rounded-br-2xl'
              : `${isReply ? 'bg-primary' : 'bg-[#766BA8]'} text-[white]`
          }`}
        >
          {content.split('\n').map((line: string, index: number) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
          {senderByAdmin && rating && (
            <div className="flex justify-end ">
              <Rate
                className="pt-[3px]  pl-[16px] mt-[4px] bg-[#FFFFFF1A] w-[180px]  rounded-xl  h-[40px] rating-container"
                disabled
                value={rating}
              />
            </div>
          )}
        </p>
      </div>
    </div>
  );
};

export default ChatWithRating;
