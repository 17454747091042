import { LeftOutlined } from '@ant-design/icons';
import accessCodeApi from '@apis/accessCode.api';
import { Clinic } from '@models/AccessCode.model';
import routes from '@routes';
import { errorCatchHandler } from '@utils/errorCatch.util';
import { Button, Form, Input, message } from 'antd';
import axios, { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CodeGenerator from '../add-access-code/components/CodeGenerator';
import './AccessForPatientOfClinic.scss';

interface EditPatientsOfaClinicProps {
  clinicData: Clinic;
}

const EditPatientsOfaClinic = ({ clinicData }: EditPatientsOfaClinicProps) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<'idle' | 'failed' | 'success'>('idle');
  const navigate = useNavigate();
  const [errorContent, setErrorContent] = useState('');
  const [, forceUpdate] = useState({});
  useEffect(() => {
    form.setFieldsValue(clinicData);
    form.resetFields();
  }, [form, clinicData]);
  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);
  useEffect(() => {
    if (status === 'idle') return;
    if (status === 'failed') {
      message.error(errorContent);
      setStatus('idle');
    }
    if (status === 'success') {
      message.success('Successful edited');
      navigate(routes.AccessCode.path + 'clinic');
    }
  }, [status, errorContent, navigate]);
  const onFinish = async (values: any) => {
    try {
      if (clinicData) {
        const result = {
          code: values.code,
          // numberOfUses: values.numberOfUses,
          clinicName: values.clinicName,
          // startDate: moment(values.dates[0]).utc().format(),
          // endDate: moment(values.dates[1]).utc().format(),
          // journeyIds: getJourneyIds(values.journeyIds),
        };
        setIsLoading(true);
        const response = await accessCodeApi.editAccessCodeForClinic(clinicData.id, result);
        if (response.data.success) {
          setStatus('success');
        }
      }
    } catch (err) {
      const errors = err as Error | AxiosError;
      if (!axios.isAxiosError(errors)) {
        setErrorContent(errors.message);
      }
      const axiosErr = errors as AxiosError;
      setErrorContent(axiosErr.response?.data.message);
      setStatus('failed');
    } finally {
      setIsLoading(false);
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    setIsLoading(false);
    errorCatchHandler(errorInfo);
  };
  return (
    <>
      <Form
        form={form}
        layout="vertical"
        style={{ maxWidth: '100%' }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className={`addClinic flex flex-col gap-6`}
        initialValues={{
          ...clinicData,
        }}
      >
        <div className={` w-full h-fit flex flex-rows justify-between`}>
          <div className={`flex flex-row justify-center items-center gap-8`}>
            <LeftOutlined
              className={`text-primary text-[22px]`}
              onClick={() => {
                navigate(routes.AccessCode.path + 'clinic');
              }}
            />
            <div className={`text-3xl font-bold text-primary`}>Patients of a clinic</div>
          </div>
          <div className={`w-fit h-fit`}>
            <Form.Item shouldUpdate>
              {() => (
                <Button
                  htmlType="submit"
                  type="primary"
                  className={`w-80 h-fit p-3 rounded-md
                    text-[16px] font-bold text-white
                    bg-primary border-0
                    cursor-pointer disabled:bg-[#a6a2bc]
                    disabled:hover:bg-[#a6a2bc] disabled:hover:text-white
                    disabled:text-white disabled:border-none disabled:cursor-not-allowed`}
                  disabled={
                    isLoading ||
                    !!form.getFieldsError().filter(({ errors }) => errors.length).length ||
                    form.getFieldValue('clinicName') === '' ||
                    // form.getFieldValue('journeyIds').length === 0 ||
                    form.getFieldValue('numberOfUses') === '' ||
                    form.getFieldValue('code') === ''
                  }
                >
                  {!isLoading && <>Save</>}
                  {isLoading && <>Saving...</>}
                </Button>
              )}
            </Form.Item>
          </div>
        </div>
        <div className={`content flex flex-col justify-center gap-4`}>
          <Form.Item
            label={'1. Name of a clinic'}
            name={'clinicName'}
            rules={[
              {
                required: true,
                message: 'Please enter the name of the clinic',
              },
            ]}
          >
            <Input
              placeholder="Enter the name of the clinic"
              maxLength={50}
              showCount
              disabled={isLoading}
            />
          </Form.Item>
          <Form.Item
            name={'code'}
            label="2.Access code"
            rules={[
              {
                required: true,
                message: 'Please generate an access code',
              },
            ]}
          >
            <CodeGenerator
              initialValue={clinicData.code}
              codeFieldName="code"
              form={form}
              disable={isLoading}
            />
          </Form.Item>
        </div>
      </Form>
    </>
  );
};
export default EditPatientsOfaClinic;
