import { DocumentData, Timestamp } from 'firebase/firestore/lite';
import ChatEmptyIcon from '@assets/images/icons/chat-empty-icon.svg';
import ReplyIcon from '@assets/images/icons/reply-icon.svg';
import {
  collection,
  limit,
  onSnapshot,
  query,
  orderBy,
  startAfter,
  serverTimestamp,
  getDocs,
} from 'firebase/firestore';
import { db } from '../HandleAccessFirebase';
import { useCallback, useEffect, useRef, useState } from 'react';
import { store } from '@state/store';
import { addReply } from '@state/chat/replyChatReducer';
import ChatWithRating from '../replyChat.tsx/chatWithRating';
import { Skeleton, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '@state/rootReducer';
interface ViewChatTextAreaProp {
  currentChatItem: string;
  loadingDetailChat: boolean;
  finishLoad: () => void;
}

const ViewChatTextArea = ({
  currentChatItem,
  loadingDetailChat,
  finishLoad,
}: ViewChatTextAreaProp) => {
  const [chatDetail, setChatDetail] = useState<DocumentData[]>([]);
  const [lastDoc, setLastDoc] = useState<DocumentData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [defaultHeight, setDefaultHeight] = useState<number>(0);
  const [loadMoreEmpty, setLoadMoreEmpty] = useState<string[]>([]);
  const currentMessage = useSelector((state: RootState) => state.replyChat.messageId);
  const replyChatId = useSelector((state: RootState) => state.replyChat.chatId);

  const getCurrentChatId = useSelector((state: RootState) => state.privateChat.currentChatItem);
  const [limitItem, setLimitItem] = useState<number>(
    useSelector((state: RootState) => state.privateChat.limitItem)
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const messageRef = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const scrollToMessage = (messageId: string) => {
    const element = messageRef.current[messageId];
    if (element) {
      setTimeout(() => {
        element.scrollIntoView();
      }, 300);
    }
  };

  const scrollToBottom = (loadMore: boolean) => {
    const chatContainer = document.getElementById(`chat-container${currentChatItem}`);
    if (currentMessage && currentChatItem === replyChatId) {
      scrollToMessage(currentMessage);
      return;
    }
    if (loadMore && chatContainer) {
      const currentScrollTop = Math.floor(chatContainer.scrollHeight / defaultHeight);
      chatContainer.scrollTop = chatContainer.scrollHeight - defaultHeight * currentScrollTop;
    }
    if (!loadMore && chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
      setDefaultHeight(chatContainer.scrollHeight);
    }
  };

  const getAuthUser = localStorage.getItem('currentUserId');

  const timestampToMillisecond = (time: Timestamp) => {
    return time.seconds * 1000 + Math.floor(time.nanoseconds / 1000000);
  };
  const transferTimeStamp = (time: Timestamp) => {
    let milliseconds = 0;
    if (time) {
      milliseconds = time.seconds * 1000 + time?.nanoseconds / 1e6;
    }
    const date = new Date(milliseconds);
    const dateString = date.toISOString().replace('T', ' ').slice(0, -1);
    const getTime = dateString.slice(11, 16);
    return getTime;
  };

  const checkToday = (date: Date) => {
    const currentDate = new Date();
    return (
      date.getFullYear() === currentDate.getFullYear() &&
      date.getMonth() === currentDate.getMonth() &&
      date.getDate() === currentDate.getDate()
    );
  };

  const transferToDate = (time: Timestamp) => {
    const date = new Date(timestampToMillisecond(time));
    const isToday = checkToday(date);
    if (isToday) {
      return 'Today';
    }
    const dateParts = date.toString().split(' ');
    const dateOnly = dateParts.slice(0, 4).join(' ');
    return dateOnly;
  };

  const compareTimeStamp = (firstTime: Timestamp, lastTime: Timestamp) => {
    const millis1 = timestampToMillisecond(firstTime);
    const millis2 = timestampToMillisecond(lastTime);
    const date1 = new Date(millis1);
    const date2 = new Date(millis2);
    const isToday1 = checkToday(date1);
    const isToday2 = checkToday(date2);
    if (isToday1 && !isToday2) {
      return false;
    }
    if (isToday1 && isToday2) {
      return true;
    }

    // Calculate the difference in milliseconds
    const differenceMillis = Math.abs(millis1 - millis2);

    // Define one day in milliseconds
    const oneDayMillis = 24 * 60 * 60 * 1000;

    // Check if the difference is within one day
    const isWithinOneDay = differenceMillis <= oneDayMillis;

    return isWithinOneDay;
  };

  const loadMessages = async () => {
    if (loadMoreEmpty.includes(currentChatItem)) {
      return;
    }
    setLoading(true);
    const q = query(
      collection(db, `ana-private-chat/private-chat/chats/${getCurrentChatId}/messages`),
      orderBy('timestamp', 'desc'),
      startAfter(lastDoc),
      limit(10)
    );
    const getDetail = await getDocs(q);
    if (getDetail.docs.length === 0) {
      setLoadMoreEmpty((prevLoadMoreEmpty) => [...prevLoadMoreEmpty, getCurrentChatId]);
      setLoading(false);
    } else {
      setLimitItem(limitItem + 15);
      setLoading(false);
    }
  };
  const handleScroll = useCallback(() => {
    const container = containerRef.current;
    if (container) {
      if (container.scrollTop === 0) {
        loadMessages();
      }
    }
  }, [loadMessages]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);

      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll]);

  const handleReplyChat = (messageId: string, text: string, rating: number) => {
    const payload = {
      senderId: getAuthUser ? getAuthUser : '',
      text: text,
      chatId: currentChatItem,
      messageId: messageId,
      rating: rating,
    };
    store.dispatch(addReply(payload));
  };

  useEffect(() => {
    const chatDetailQuery = query(
      collection(db, `ana-private-chat/private-chat/chats/${currentChatItem}/messages`),
      orderBy('timestamp', 'desc'),
      limit(limitItem)
    );

    const chatDetailSnapshot = onSnapshot(chatDetailQuery, (snapShot) => {
      if (snapShot.docs.length === 0) {
        finishLoad();
        return;
      }
      const getMessages: DocumentData[] = [];
      snapShot.docs.forEach((doc) => {
        getMessages.push({ ...doc.data(), id: doc.id });
      });
      if (getMessages) {
        setChatDetail(getMessages.sort((a, b) => a.timestamp - b.timestamp));
      }
      setLastDoc(snapShot.docs[snapShot.docs.length - 1]);
      scrollToBottom(!(limitItem <= 15));
      finishLoad();
    });

    return () => {
      chatDetailSnapshot();
    };
  }, [currentChatItem, limitItem]);

  return (
    <>
      <>
        {loadingDetailChat && (
          <Skeleton className="w-full h-full p-[20px] z-30 absolute bg-white " active />
        )}
        {chatDetail.length > 0 ? (
          <div
            className="flex flex-col p-[20px] view-detail-chat-header h-[65%]  overflow-y-auto"
            id={`chat-container${currentChatItem}`}
            ref={containerRef}
          >
            {loading && <Spin className="flex justify-center w-full" size="small" />}
            {chatDetail.map((detail, index) => (
              <div ref={(el) => (messageRef.current[detail.id] = el)}>
                {chatDetail[index - 1] &&
                  !compareTimeStamp(
                    detail.timestamp,
                    chatDetail[index - 1].timestamp
                      ? chatDetail[index - 1].timestamp
                      : serverTimestamp()
                  ) && (
                    <div className="w-full flex justify-center mb-[10px]">
                      {transferToDate(detail.timestamp)}
                    </div>
                  )}
                <div
                  id="chat-container"
                  className={`w-full  flex ${
                    !detail.sendByAdmin ? 'justify-start' : 'justify-end'
                  }`}
                >
                  <div className="flex flex-col">
                    <div
                      className={`${
                        detail.rating || detail.content.replyText ? 'flex flex-row h-full' : ''
                      }`}
                    >
                      {detail.rating && (
                        <div className="h-full mr-[10px] pt-[12%]">
                          <img
                            alt=""
                            src={ReplyIcon}
                            className="w-[32px] h-[32px] cursor-pointer "
                            onClick={() =>
                              handleReplyChat(
                                detail.id,
                                detail.content.replyText
                                  ? detail.content.replyText
                                  : detail.content.text,
                                detail.rating
                              )
                            }
                          />
                        </div>
                      )}
                      {detail.content.replyText ? (
                        <div className={`max-w-[580px] w-full font-[16px] mb-[8px] flex flex-col `}>
                          <div
                            className={`w-full flex ${
                              !detail.sendByAdmin ? 'justify-start' : 'justify-end'
                            }`}
                          >
                            <p
                              className={`w-full break-words p-[12px] flex flex-col  m-0 rounded-2xl rounded-br-none ${
                                !detail.sendByAdmin
                                  ? 'bg-[#F0F0F0] text-[#000000]'
                                  : 'bg-[#766BA8] text-[white]'
                              }`}
                            >
                              <div className="flex w-full justify-start">
                                <ChatWithRating
                                  senderByAdmin={detail.sendByAdmin}
                                  content={detail.content.text}
                                  rating={detail.content.rating}
                                  messageTypeReply={detail.messageType === 'reply'}
                                  isReply={detail.content.replyText}
                                />
                              </div>
                              <div className="flex w-full justify-start">
                                <ChatWithRating
                                  senderByAdmin={detail.sendByAdmin}
                                  content={detail.content.replyText}
                                  messageTypeReply={detail.messageType === 'reply'}
                                  rating={detail.rating}
                                  isReply={false}
                                />
                              </div>
                            </p>
                          </div>
                        </div>
                      ) : (
                        <ChatWithRating
                          senderByAdmin={detail.sendByAdmin}
                          content={detail.content.text}
                          messageTypeReply={detail.messageType === 'reply'}
                          rating={detail.rating}
                          isReply={detail.content.replyText}
                        />
                      )}
                    </div>
                    <div>
                      {(!chatDetail[index + 1] ||
                        chatDetail[index + 1].senderId !== detail.senderId) && (
                        <div
                          className={`w-full flex text-[14px] text-[#919191] ${
                            !detail.sendByAdmin ? 'justify-start' : 'justify-end'
                          } `}
                        >
                          Delivered {transferTimeStamp(detail.timestamp)}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-col pl-[20%] pt-[20%] pr-[20%] view-detail-chat-header h-[65%]">
            <div className="w-full flex justify-center">
              <img alt="" src={ChatEmptyIcon} className=" w-[125px] h-[125px] "></img>
            </div>
            <div className="w-full flex justify-center">
              <p className="text-[24px] text-[#DADADA]">No message available</p>
            </div>
          </div>
        )}
      </>
    </>
  );
};
export default ViewChatTextArea;
