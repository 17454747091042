import UserIcon from '@assets/images/icons/userIcon.svg';
import CompanyIcon from '@assets/images/icons/company.svg';
import ClinicIcon from '@assets/images/icons/clinic.svg';
import routes from '@routes';

export interface AccessPermission {
  title: string;
  color: string;
  icon: string;
  path: string;
}

export const accessPerMissionType: AccessPermission[] = [
  {
    title: 'Individuals of a specific company',
    color: '#395d94',
    icon: UserIcon,
    path: routes.AccessForCompanyEmployee.path,
  },
  {
    title: 'Whole company',
    color: '#CFD47D',
    icon: CompanyIcon,
    path: routes.AccessForWholeCompany.path,
  },
  {
    title: 'Patients of a clinic',
    color: '#219EBC',
    icon: ClinicIcon,
    path: routes.AccessForPatient.path,
  },
];
