import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';
import DeleteIcon from '@assets/images/icons/deleteIcon.svg';
import { capitalizeFirstLetter } from '@utils/capitalFirstLetter.util';
import './CustomModal.scss';

interface DeleteButtonProps {
  deleteClass: string;
  onDelete: () => void;
}

const DeleteButton = ({ deleteClass, onDelete }: DeleteButtonProps) => {
  const { confirm } = Modal;
  const icon = <ExclamationCircleOutlined style={{ color: '#D01616' }} />;
  const title = (
    <h3 className={`font-bold text-primary text-left`}>{`Remove this ${deleteClass}?`}</h3>
  );
  const content = (
    <h4
      className={`text-black font-normal text-[16px] text-center`}
    >{`Are you sure you want to remove this ${capitalizeFirstLetter(deleteClass)}?`}</h4>
  );
  const onOk = async () => {
    onDelete();
  };
  const onCancel = () => {
    return;
  };
  const bodyStyle = {
    background: 'white',
    borderRadius: 10,
    width: 'fit-content',
    padding: 'none',
  };
  const okButtonProps = {
    style: {
      borderRadius: 4,
      width: 'fit-content',
      height: 40,
      backgroundColor: '#4D4479',
      transition: 'filter 0.3s',
      fontFamily: "'WorkSans', sans-serif",
    },
    onMouseEnter: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.currentTarget.style.filter = 'brightness(150%)';
    },
    onMouseLeave: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.currentTarget.style.filter = 'none';
    },
  };
  const cancelButtonProps = {
    style: {
      fontFamily: "'WorkSans', sans-serif",
      borderRadius: 4,
      width: 90,
      height: 40,
      backgroundColor: '#F5F5F5',
      color: '#4D4479',
    },
  };

  const handleDelete = () => {
    confirm({
      className: 'custom-modal',
      icon: icon,
      maskClosable: true,
      centered: true,
      width: 550,
      okText: 'Remove',
      cancelText: 'No',
      autoFocusButton: null,
      title: title,
      content: content,
      onOk: onOk,
      onCancel: onCancel,
      bodyStyle: bodyStyle,
      okButtonProps: okButtonProps,
      cancelButtonProps: cancelButtonProps,
    });
  };

  return (
    <>
      <img
        src={DeleteIcon}
        alt={`delete_${deleteClass}`}
        onClick={(e) => {
          e.stopPropagation();
          handleDelete();
        }}
        className={`custom-modal w-[40px] h-[40px] mr-6 hover:cursor-pointer`}
      />
    </>
  );
};

export default DeleteButton;
