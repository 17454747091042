import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { DiagnosticType } from '../UserProfileDetail.page';
import { useNavigate } from 'react-router-dom';
interface UserProfileDiagnosticProp {
  data: DiagnosticType[];
}
const UserProfileDiagnostic = ({ data }: UserProfileDiagnosticProp) => {
  const navigate = useNavigate();
  const transferDate = (date: string) => {
    const transferDate = new Date(date);
    const dateString = transferDate.toISOString().split('T')[0];
    return dateString;
  };
  const columns: ColumnsType<DiagnosticType> = [
    {
      title: 'Mode',
      dataIndex: 'mode',
      className: 'column-first-item',
      key: 'mode',
    },
    {
      title: 'Updated',
      dataIndex: 'updated',
      key: 'updated',
      render: (updated) => {
        const dateString = transferDate(updated);
        return <div>{dateString}</div>;
      },
    },
    {
      title: 'Detail',
      dataIndex: 'detail',
      className: 'column-last-item',
      key: 'detail',
      render: (index) => {
        return (
          <a
            className=" text-[16px] text-[#2F6BFF] underline  decoration-solid hover:text-[#5384ff]"
            onClick={() => {
              navigate(`/user-profile/detail/${index}/diagnostic-detail`);
            }}
          >
            View {index}
          </a>
        );
      },
    },
  ];
  return (
    <div className="mt-[10px]">
      <Table className="table-user-profile " columns={columns} dataSource={data} />
    </div>
  );
};

export default UserProfileDiagnostic;
