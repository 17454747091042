import { store } from '@state/store';
import {
  addCurrentChatItem,
  addFirstLoading,
  addLimitItem,
  addLimitList,
  setSubscriptionExpired,
} from '../chatReducer';

export const handleSetCurrentChatItem = (currentChatItem: string) => {
  const payload = {
    unreadMessage: 0,
    firstLoading: false,
    currentChatItem: currentChatItem,
    limitItem: 15,
    limitList: 10,
    subscriptionExpired: false,
  };
  store.dispatch(addCurrentChatItem(payload));
};

export const handleSetFirstLoading = (isFirstLoading: boolean) => {
  const payload = {
    unreadMessage: 0,
    firstLoading: isFirstLoading,
    currentChatItem: '',
    limitItem: 15,
    limitList: 10,
    subscriptionExpired: false,
  };
  store.dispatch(addFirstLoading(payload));
};

export const handleSetLimit = (limitPage: number) => {
  const payload = {
    unreadMessage: 0,
    firstLoading: false,
    currentChatItem: '',
    limitItem: limitPage,
    limitList: 10,
    subscriptionExpired: false,
  };
  store.dispatch(addLimitItem(payload));
};

export const handleSetLimitList = (limitList: number) => {
  const payload = {
    unreadMessage: 0,
    firstLoading: false,
    currentChatItem: '',
    limitItem: 15,
    limitList: limitList,
    subscriptionExpired: false,
  };
  store.dispatch(addLimitList(payload));
};

export const handleSetSubscriptionExpired = (subscriptionExpired: boolean) => {
  const payload = {
    unreadMessage: 0,
    firstLoading: false,
    currentChatItem: '',
    limitItem: 15,
    limitList: 10,
    subscriptionExpired: subscriptionExpired,
  };
  store.dispatch(setSubscriptionExpired(payload));
};
