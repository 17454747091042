import loggedClient from '../https/loggedClient.http';

const baseDailyVibe = '/admin/dailyVibes';

const dailyVibeApi = {
  getDailyVibes: (pageNumber: number, pageSize: number) =>
    loggedClient.get(baseDailyVibe, { params: { pageNumber, pageSize } }),
  getDailyVibe: (dailyVibeId: string) => loggedClient.get(`${baseDailyVibe}/${dailyVibeId}`),
  filterDailyVibe: (pageNumber: number, pageSize: number, filterTag: string) =>
    loggedClient.get(baseDailyVibe, { params: { pageNumber, pageSize, filterTag } }),
  deleteDailyVibe: (dailyVibeId: string) => loggedClient.delete(`${baseDailyVibe}/${dailyVibeId}`),
  editDailyVibe: (dailyVibeId: string, data: any) =>
    loggedClient.put(`${baseDailyVibe}/${dailyVibeId}`, data),
  createDailyVibe: (data: any) => loggedClient.post(baseDailyVibe, data),
};

export default dailyVibeApi;
