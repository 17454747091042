import accessCodeApi from '@apis/accessCode.api';
import { AccessCodeData } from '@models/AccessCode.model';
import { Skeleton } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './AccessCode.scss';
import EditAccessForIndividualsOfCompany from './edit-access-code/EditAccessForIndividualsOfCompany';
import EditAccessForWholeCompany from './edit-access-code/EditAccessForWholeCompany';
import EditPatientsOfaClinic from './edit-access-code/EditPatientsOfaClinic';

const EditAccessCode = () => {
  const { id } = useParams();
  const [detail, setDetail] = useState<AccessCodeData>();
  const getAccessDetail = useCallback(async () => {
    if (id) {
      const response = await accessCodeApi.getDetailAccessCode(id);
      if (response.data.success) {
        setDetail(response.data.data);
      }
    }
  }, [id]);
  useEffect(() => {
    getAccessDetail();
  }, [getAccessDetail]);
  return (
    <div className={`edit-access-code p-[40px]`}>
      {detail ? (
        <div>
          {detail.clinic && (
            <EditPatientsOfaClinic clinicData={detail.clinic}></EditPatientsOfaClinic>
          )}
          {detail.company && (
            <div>
              {detail.company.accessPermissionType === 'companyIndividuals' ? (
                <EditAccessForIndividualsOfCompany
                  individualsCompanyDetail={detail.company}
                ></EditAccessForIndividualsOfCompany>
              ) : (
                <EditAccessForWholeCompany
                  companyDetail={detail.company}
                ></EditAccessForWholeCompany>
              )}
            </div>
          )}
        </div>
      ) : (
        <Skeleton active />
      )}
    </div>
  );
};
export default EditAccessCode;
