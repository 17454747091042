import { Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { OrderType } from '../UserProfileDetail.page';
import { useNavigate } from 'react-router-dom';
interface UserOrderProps {
  data: OrderType[];
}
const UserOrderPage = ({ data }: UserOrderProps) => {
  const navigate = useNavigate();
  const transferDate = (date: string) => {
    const transferDate = new Date(date);
    const dateString = transferDate.toISOString().split('T')[0];
    return dateString;
  };

  const getTagColor = (tagName: string) => {
    let color = '';
    if (tagName === 'Approved' || tagName === 'Complete') {
      color = 'green';
    }
    if (tagName === 'Rejected') {
      color = 'volcano';
    }
    if (tagName === 'Pending' || tagName === 'Incomplete') {
      color = 'yellow';
    }
    return color;
  };
  const columns: ColumnsType<OrderType> = [
    {
      title: 'Id',
      dataIndex: 'id',
      className: 'column-first-item',
      key: 'id',
      render: (text) => <a>{data.indexOf(text)}</a>,
    },
    {
      title: 'User Name',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: 'Request date',
      dataIndex: 'requestDate',
      key: 'requestDate',
      render: (date) => {
        const dateString = transferDate(date);
        return <div>{dateString}</div>;
      },
    },
    {
      title: 'Currency',
      key: 'currency',
      dataIndex: 'currency',
    },
    {
      title: 'Authorizing provider',
      key: 'authorizingProvider',
      dataIndex: 'authorizingProvider',
    },
    {
      title: 'Authorizing date',
      key: 'authorizingDate',
      dataIndex: 'authorizingDate',
      render: (date) => {
        const dateString = transferDate(date);
        return <div>{dateString}</div>;
      },
    },
    {
      title: 'Request status',
      key: 'requestStatus',
      dataIndex: 'requestStatus',
      render: (data) => {
        const color = getTagColor(data);
        return (
          <Tag
            className="rounded-2xl w-[88px] flex justify-center h-[21px] text-[14px]"
            color={color}
            key={data}
          >
            {data}
          </Tag>
        );
      },
    },
    {
      title: 'Order status',
      key: 'orderStatus',
      dataIndex: 'orderStatus',
      render: (data) => {
        const color = getTagColor(data);
        return (
          <Tag
            className="rounded-2xl w-[99px] flex justify-center h-[21px] text-[14px]"
            color={color}
            key={data}
          >
            {data}
          </Tag>
        );
      },
    },
    {
      title: 'View',
      dataIndex: 'userName',
      className: 'column-last-item',
      key: 'action',
      render: (index) => {
        return (
          <a
            className=" text-[16px] text-[#2F6BFF] underline  decoration-solid hover:text-[#5384ff]"
            onClick={() => {
              // navigate(`/user-profile/detail/${index}/order-detail`);
            }}
          >
            View {index}
          </a>
        );
      },
    },
  ];
  return (
    <div className="mt-[10px]">
      <Table className="table-user-profile " columns={columns} dataSource={data} />
    </div>
  );
};

export default UserOrderPage;
