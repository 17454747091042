import SkeletonDot from '@components/SkeletonDot';
import { formatAccessCode, generateCode, mergeAccessCode } from '@utils/generateAccessCode.util';
import { Button } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';

interface CodeGeneratorProp {
  codeFieldName: string;
  form: FormInstance;
  disable?: boolean;
  initialValue?: string;
}

const TIME_BETWEEN_GENERATING = 35;

const CodeGenerator = ({
  codeFieldName,
  form,
  disable = false,
  initialValue,
}: CodeGeneratorProp) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [code, setCode] = useState('');
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (timer) clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (initialValue) {
      setCode(initialValue);
    }
  }, [initialValue]);

  const generateAccessCode = async () => {
    setIsGenerating(true);
    let generateCount = -1;

    const codeInterval = setInterval(async () => {
      const newCode = await generateCode();
      setCode((prev) => mergeAccessCode(prev, newCode, generateCount));
      generateCount++;

      if (generateCount === 9 && codeInterval) {
        clearInterval(codeInterval);
      }
    }, TIME_BETWEEN_GENERATING);

    setTimer(codeInterval);
    setIsGenerating(false);
  };

  const handleButtonClick = () => {
    if (timer) {
      clearInterval(timer);
    }

    generateAccessCode();
  };

  useEffect(() => {
    form.setFieldValue(codeFieldName, formatAccessCode(code));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return (
    <div className={`flex flex-row w-full gap-4`}>
      <div
        className={`w-[75%] h-[55px] p-2 px-6 flex items-center
        border-primary border-[1px] border-solid rounded-lg
        text-[16px] text-black `}
      >
        {!isGenerating && (
          <>
            {code === '' ? (
              <span className={`text-gray-300`}>Access code generated</span>
            ) : (
              <h4 className={`text-lg mt-2`}>{formatAccessCode(code)}</h4>
            )}
          </>
        )}
        {isGenerating && <SkeletonDot />}
      </div>
      <div className={`w-[25%] h-fit `}>
        <Button
          htmlType="button"
          type="primary"
          className={`w-full h-fit p-4 flex justify-center items-center rounded-lg 
        border-none disabled:bg-[#a6a2bc] disabled:hover:bg-[#a6a2bc]
        disabled:text-white disabled:border-none disabled:cursor-not-allowed
        bg-primary cursor-pointer `}
          disabled={isGenerating || disable}
          onClick={handleButtonClick}
        >
          {!isGenerating && (
            <div className={`flex flex-row gap-2`}>
              <span className={`text-white text-center text-[14px]`}>Generate an access code</span>
            </div>
          )}
          {isGenerating && <span className={`text-white text-center`}>Generating...</span>}
        </Button>
      </div>
    </div>
  );
};

export default CodeGenerator;
