import { CopyOutlined } from '@ant-design/icons';
import { Button, Tooltip, message } from 'antd';

interface ClipboardState {
  text: string;
}

const ClipboardButton = ({ text }: ClipboardState) => {
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.success('Copied to the clipboard');
      })
      .catch(() => {
        message.error('Failed to copy');
      });
  };

  return (
    <div>
      <Tooltip placement="right" title={'Copy this code'}>
        <Button
          onClick={copyToClipboard}
          className="border-1 border-transparent bg-transparent 
          shadow-none hover:bg-transparent hover:border-1
          hover:border-primary hover:border-solid rounded-lg"
        >
          <div className={`text-[#424042]`}>
            <CopyOutlined />
          </div>
        </Button>
      </Tooltip>
    </div>
  );
};

export default ClipboardButton;
