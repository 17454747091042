import { message } from 'antd';
import axios, { AxiosError } from 'axios';

export const errorCatchHandler = (err: any) => {
  const errors = err as Error | AxiosError;
  if (!axios.isAxiosError(errors)) {
    message.error(errors.message);
    return;
  }

  const axiosErr = errors as AxiosError;
  if (!Array.from(axiosErr.response?.data.errors).length) {
    message.error(axiosErr.response?.data.message);
  } else {
    message.error(axiosErr.response?.data.errors[0]);
  }
};
