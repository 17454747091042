import { createSlice, SerializedError } from "@reduxjs/toolkit";
import User from "../../models/User.model";
import {
  isFulfilledAction,
  isPendingAction,
  isRejectAction,
} from "../common.matcher";
import { signInAsync, signOutAsync } from "./auth.thunks";

interface AuthState {
  isLoading: boolean;
  accessToken?: string;
  accountLogged?: User;
  errorMessage?: string;
  isRememberAccount: boolean;
}

const initialState: AuthState = {
  isLoading: false,
  accessToken: undefined,
  accountLogged: undefined,
  errorMessage: undefined,
  isRememberAccount: true,
};

const authSliceName = "@Auth";

const authSlice = createSlice({
  name: authSliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signOutAsync.fulfilled, () => {
        return initialState;
      })
      .addCase(signInAsync.fulfilled, (state, action) => {
        state.accessToken = action.payload.accessToken;
        state.accountLogged = action.payload.user;
      })
      .addMatcher(
        (action) => isPendingAction(action, authSliceName),
        (state) => {
          state.isLoading = true;
          state.errorMessage = undefined;
        }
      )
      .addMatcher(
        (action) => isFulfilledAction(action, authSliceName),
        (state) => {
          state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => isRejectAction(action, authSliceName),
        (state, action) => {
          state.isLoading = false;
          state.errorMessage = `Error: ${
            (action.error as SerializedError)?.message}`;
        }
      );
  },
});

export default authSlice.reducer;
