import accessCodeApi, {
  AccessCodeSortingInformation,
  AccessCodeSortingType,
} from '@apis/accessCode.api';
import ClipboardButton from '@components/ClipboardButton';
import DeleteButton from '@components/DeleteButton';
import EditBtn from '@components/EditBtn';
import { Clinic } from '@models/AccessCode.model';
import routes from '@routes';
import { errorCatchHandler } from '@utils/errorCatch.util';
import { Pagination, Table, message } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ViewAccessCode.scss';

const initPagination = {
  page: 1,
  limit: 6,
};

const initialSortingInfo: AccessCodeSortingInformation = {
  by: '',
  order: 'desc',
  isActive: false,
};

const ViewAccessCodeClinic = () => {
  const navigate = useNavigate();
  const [clinics, setClinics] = useState<Clinic[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState(initPagination);
  const [totalFeedback, setTotalFeedback] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [sortingInfo, setSortingInfo] = useState<AccessCodeSortingInformation>(initialSortingInfo);

  const getAccessCodes = useCallback(
    async (justDeleted = false) => {
      setIsFetching(true);
      const response = await accessCodeApi.getAccessCode(
        totalFeedback % initPagination.limit === 1 && justDeleted
          ? pagination.page === 1
            ? pagination.page
            : pagination.page - 1
          : pagination.page,
        pagination.limit,
        'clinic',
        sortingInfo.by,
        sortingInfo.order
      );
      if (response.data.success) {
        const fetchedClinic = response.data.data.clinics;
        const convertClinic: Clinic[] = fetchedClinic.map((clinic: any) => {
          const newClinic: Clinic = { ...clinic };
          return newClinic;
        });
        setClinics(convertClinic);
        if (totalFeedback % initPagination.limit === 1 && justDeleted)
          setPagination({ page: response.data.data.currentPage, limit: initPagination.limit });
        setTotalFeedback(response.data.data.totalCount);
        setCurrentPage(response.data.data.currentPage);
      }
      setIsFetching(false);
    },
    [pagination, totalFeedback, sortingInfo]
  );

  const removeAccessCode = async (accessId: string) => {
    try {
      setDeleteId(accessId);
      const response = await accessCodeApi.deleteAccessCode(accessId);
      if (response.data.success) {
        message.success('Deleted successfully');
        getAccessCodes(true);
      }
    } catch (err) {
      errorCatchHandler(err);
    } finally {
      setDeleteId('');
    }
  };

  const sortingProps = useCallback(
    (columnName: AccessCodeSortingType, name: string) => {
      return {
        onHeaderCell: () => {
          return {
            onClick: () => {
              setSortingInfo((prev) => {
                if (prev.by === columnName && prev.order === 'desc') return initialSortingInfo;
                return {
                  by: columnName,
                  order: prev.order === 'asc' ? 'desc' : 'asc',
                  isActive: true,
                };
              });
            },
            style: {
              cursor: 'pointer',
              height: '50px',
              backgroundColor:
                sortingInfo.by === columnName && sortingInfo.isActive ? '#f3f3f3' : '',
              paddingLeft: '20px',
              paddingBottom: '0px',
              paddingTop: '0px',
            },
          };
        },
        onCell: () => {
          return {
            style: {
              backgroundColor:
                sortingInfo.by === columnName && sortingInfo.isActive ? '#f3f3f3' : '',
            },
          };
        },
        sorter: () => {
          return 0;
        },
        showSorterTooltip: isFetching
          ? false
          : {
              title: () => {
                return sortingInfo.isActive && sortingInfo.order === 'desc'
                  ? sortingInfo.order === 'desc'
                    ? 'Click to sorting by active date from latest to earliest'
                    : 'Click to sort by active date from earliest to latest'
                  : 'Click to sort by ' +
                      name +
                      ' in ' +
                      (sortingInfo.order === 'asc' ? 'des' : 'asc') +
                      'cending order';
              },
              color: '#4D4479',
              style: {
                minWidth: 'fit-content',
              },
            },
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortingInfo, setSortingInfo]
  );

  useEffect(() => {
    getAccessCodes();
  }, [pagination, getAccessCodes]);

  const columns: ColumnsType<Clinic> = [
    {
      title: 'Access code',
      dataIndex: 'code',
      key: 'code',
      render: (text) => {
        return (
          <div className={`flex flex-row gap-2 items-center justify-between`}>
            {text}
            <ClipboardButton text={text} />
          </div>
        );
      },
      onCell: () => {
        return {
          style: {
            width: '200px',
          },
        };
      },
    },
    {
      title: 'Clinic',
      key: 'clinicName',
      dataIndex: 'clinicName',
      ...sortingProps('clinicName', 'name of clinic'),
      onCell: () => {
        return {
          style: {
            width: '150px',
            padding: '0px 0px 0px 20px',
            backgroundColor:
              sortingInfo.by === 'clinicName' && sortingInfo.isActive ? '#f3f3f3' : '',
          },
        };
      },
    },
    {
      title: 'Created by',
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: (_, record) => {
        return <>{record.createdBy.name}</>;
      },
      onCell: () => {
        return {
          style: {
            width: '120px',
          },
        };
      },
    },

    {
      title: '',
      key: 'action',
      render: (_, { id }) => {
        return (
          <>
            <>
              {deleteId !== id ? (
                <span className={`flex flex-row gap-3`}>
                  <EditBtn
                    openEdit={() => {
                      navigate(routes.EditAccessCode.prePath + id);
                    }}
                  />
                  <DeleteButton
                    onDelete={() => {
                      removeAccessCode(id);
                    }}
                    deleteClass={'clinic'}
                  />
                </span>
              ) : (
                <div className={`text-red-600 text-lg`}>Deleting...</div>
              )}
            </>
          </>
        );
      },
      onCell: () => {
        return {
          style: {
            width: '140px',
          },
        };
      },
    },
  ];

  const onChange: TableProps<Clinic>['onChange'] = (page) => {
    if (pagination.page !== page.current) {
      setPagination({
        page: page.current || initPagination.page,
        limit: page.pageSize || initPagination.limit,
      });
      setCurrentPage(page.current as number);
    }
  };

  const onPageChange = (page: number, pageSize: number) => {
    setPagination({ page: page, limit: pageSize });
    setCurrentPage(page);
  };

  return (
    <div className={`flex flex-col gap-11`}>
      <div>
        <Table
          columns={columns}
          rowKey="id"
          dataSource={clinics}
          onChange={onChange}
          loading={isFetching}
          pagination={false}
        />
      </div>
      <div className={`flex items-center justify-center w-full h-full`}>
        <Pagination
          onChange={onPageChange}
          pageSize={pagination.limit}
          total={totalFeedback}
          current={currentPage}
          pageSizeOptions={[initPagination.limit, 20, 40]}
        />
      </div>
    </div>
  );
};

export default ViewAccessCodeClinic;
