import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./auth/auth.slice";
import commonReducer from "./common/common.slice";
import userReducer from "./user/userReducer";
import replyChatReducer from "./chat/replyChatReducer";
import privateChatReducer from "./chat/chatReducer";
import ratingReducer from "./rating/ratingReducer";
import anaProgramReducer from "./ana-program/anaProgramReducer";
import userProfileReducer from "./user-profile/userProfileReducer";

const appReducer = combineReducers({
  auth: authReducer,
  common: commonReducer,
  userChat: userReducer,
  replyChat: replyChatReducer,
  privateChat: privateChatReducer,
  ratingList: ratingReducer,
  anaProgram: anaProgramReducer,
  userProfile: userProfileReducer
});

const persistConfig = {
  key: "ana-admin",
  storage,
  whitelist: ["auth"]
};

const rootReducer = (state: any, action: any) => {
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default persistReducer(persistConfig, rootReducer);
