export const handleGetSortDirection = (sortType: string | undefined) => {
  let sortDirection = undefined;
  if (sortType === 'ascend') {
    sortDirection = 'asc';
  }
  if (sortType === 'descend') {
    sortDirection = 'desc';
  }
  if (sortType === undefined) {
    sortDirection = undefined;
  }
  return sortDirection;
};
