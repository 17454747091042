import UserIcon from '@assets/images/icons/user-icon.svg';
import PhoneIcon from '@assets/images/icons/phone-icon.svg';
import EmailIcon from '@assets/images/icons/email-icon.svg';
import UserDetailModel from '@models/UserDetail.model';
interface UserDetailProps {
  userDetail: UserDetailModel | undefined;
}
const UserDetail = ({ userDetail }: UserDetailProps) => {
  const formatDate = (dateString: string | undefined) => {
    if (dateString) {
      const date = new Date(dateString);
      const formattedDate = new Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
      }).format(date);
      const ageDiffMs = Date.now() - date.getTime();
      const ageDate = new Date(ageDiffMs);
      const age = Math.abs(ageDate.getUTCFullYear() - 1970);

      return (
        <>
          {formattedDate} (<strong>{age} yrs</strong>)
        </>
      );
    }
  };

  return (
    <div className="detail-container w-full shadow-[#00000021] rounded-xl p-[20px] mt-[20px]">
      <div className=" flex flex-row justify-between border-t-0 border-l-0 border-r-0 border-b-[1px] border-solid border-[#C2C2C2] ">
        <div className="flex flex-row align-top">
          <p className="text-[24px] text-primary font-bold">PT101 - {userDetail?.name}</p>
          <p className="ml-[24px] text-[16px] text-[#606060] mt-[4px]">
            {formatDate(userDetail?.birthdate)}
          </p>
        </div>
        <div className="text-[14px] text-primary p-[8px] h-fit rounded-2xl bg-[#F0EDFF]">
          • {userDetail?.userMode?.statusDescription}
        </div>
      </div>
      <div className="grid grid-rows-3 grid-cols-2 grid-flow-col gap-4 mt-[20px] w-[280px]">
        <p className="text-[16px] text-[#000000] flex flex-row font-semibold">
          <img alt="" className="w-[24px] h-[24px] mr-[10px]" src={UserIcon} />
          Username:
        </p>
        <p className="text-[16px] text-[#000000] flex flex-row font-semibold">
          <img alt="" className="w-[24px] h-[24px] mr-[10px]" src={PhoneIcon} />
          Phone:
        </p>
        <p className="text-[16px] text-[#000000] flex flex-row font-semibold">
          <img alt="" className="w-[24px] h-[24px] mr-[10px]" src={EmailIcon} />
          Email:
        </p>
        <p className="text-[16px] text-[#4C4C4C]  font-normal w-[400px] ">{userDetail?.name}</p>
        <p className="text-[16px] text-[#4C4C4C]  font-normal w-[400px]">
          {userDetail?.phoneNumber}
        </p>
        <p className="text-[16px] text-[#4C4C4C]  font-normal w-[400px]">{userDetail?.email}</p>
      </div>
    </div>
  );
};
export default UserDetail;
