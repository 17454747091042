import loggedClient from '../https/loggedClient.http';

const baseJourney = '/admin/chatNotifications';
const refreshUrl = '/admin/conversationSubscriptions'
const privateChatUrl = '/admin/privateChats';

const privateChatApi = {
  sendNotification: (data: any) =>
    loggedClient.post(`${baseJourney}`, data),
  conversationSubscriptionRefresh: () =>
    loggedClient.post(`${refreshUrl}/Sync`),
  getRatingList: (pageNumber: number, pageSize: number) =>
    loggedClient.get(`${privateChatUrl}/rating`, { params: { pageNumber, pageSize } })
  ,
  searchRatingList: (pageNumber: number, pageSize: number, search: string) =>
    loggedClient.get(`${privateChatUrl}/rating`, { params: { pageNumber, pageSize, search } })
  ,
  replyMessage: (messageId: string, payload: any) =>
    loggedClient.put(`${privateChatUrl}/${messageId}/reply`, payload),
  sortRatingList: (
    pageNumber: number, pageSize: number, sortField: string | undefined, sortDirection: string | undefined
  ) => loggedClient.get(`${privateChatUrl}/rating`, { params: { pageNumber, pageSize, sortField, sortDirection } }),
};

export default privateChatApi;
