import loggedClient from '@https/loggedClient.http';
import AccountLogin from '../models/AccountLogin.model';
import { Amplify, Auth } from 'aws-amplify';

const baseUser = '/admin/users';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  },
});
const accountApi = {
  login: async (request: AccountLogin) => {
    return await Auth.signIn(request.email, request.password);
  },
  logout: async () => {
    return await Auth.signOut();
  },
  getUser: async (userId: string) => {
    return await loggedClient.get(`${baseUser}/${userId}`);
  },
  getListUser: async (pageNumber: number, pageSize: number) => {
    return await loggedClient.get(`${baseUser}`, { params: { pageNumber, pageSize } });
  },
  sortUserList: (
    pageNumber: number, pageSize: number, sortField: string, sortDirection: string | undefined
  ) => loggedClient.get(`${baseUser}`, { params: { pageNumber, pageSize, sortField, sortDirection } }),
  searchUserList: (pageNumber: number, pageSize: number, search: string) =>
    loggedClient.get(`${baseUser}`, { params: { pageNumber, pageSize, search } })
  ,
};

export default accountApi;
