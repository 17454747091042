import { Layout, Space } from 'antd';
import { ReactChild } from 'react';
import Sidebar from './Sidebar';
import { useEffect } from 'react';
import './PageContainer.styles.scss';
import firebaseApi from '@apis/firebase.api';
import { auth } from '@pages/private-chat/HandleAccessFirebase';
import { signInWithCustomToken } from '@firebase/auth';
import { jwtDecode } from 'jwt-decode';
import privateChatApi from '@apis/privateChat.api';
const { Content } = Layout;

type Props = {
  children: ReactChild;
};

const PageContainer = ({ children }: Props) => {
  const handleConversationSubscriptionRefresh = async () => {
    try {
      const response = await firebaseApi.getAuthenticationToken();

      if (response.data.success && auth.currentUser === null) {
        const token = response.data.data.token;
        signInWithCustomToken(auth, token);
      }

      await privateChatApi.conversationSubscriptionRefresh();
    } catch (error) {}
  };
  useEffect(() => {
    handleConversationSubscriptionRefresh();
  }, []);
  return (
    <Space>
      <Layout>
        <Sidebar />
        <Layout>
          <Content
            className={`relative 
            bg-white 
            rounded-[20px] m-[20px]  
            h-full overflow-scroll overflow-x-hidden overflow-y-auto`}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </Space>
  );
};

export default PageContainer;
