interface DiagnosticContainerProps {
  title: string;
  detail: string;
  timeStamp?: string;
}
const DiagnosticContainer = ({ title, detail, timeStamp }: DiagnosticContainerProps) => {
  return (
    <div className="w-full h-full p-[20px] bg-[#F5F5F5] rounded-2xl mb-[12px]">
      <p className="text-primary text-[20px] font-bold mb-[10px]">{title}</p>
      {timeStamp ? (
        <p className="text-[#919191] text-[14px]">Last update on {timeStamp}</p>
      ) : (
        <div />
      )}
      <p className="text-[#4C4C4C] text-[16px] ">{detail}</p>
    </div>
  );
};

export default DiagnosticContainer;
