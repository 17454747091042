import { Select } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import AddButton from './AddButton';
interface PageHeaderProps {
  addNewFunction?: () => void;
  handleChange?: (value: string, type: string) => void;
  options?: DefaultOptionType[];
  title: string;
  filterPlaceholder?: string;
  onSessionPage?: boolean;
  filteredByCategory?: DefaultOptionType[];
  filteredByStatus?: DefaultOptionType[];
  onAccessCodePage?: boolean;
  titleHeader?: string;
}

const PageHeader = ({
  addNewFunction,
  handleChange = () => {},
  options,
  title,
  filterPlaceholder,
  onSessionPage,
  filteredByCategory,
  filteredByStatus,
  onAccessCodePage,
  titleHeader = 'Ana Content Management Dashboard',
}: PageHeaderProps) => {
  return (
    <div className={`page-header flex flex-col justify-start`}>
      <div className={`flex justify-center`}>
        <p className={`font-bold text-primary text-[40px] m-auto text-center`}>{titleHeader}</p>
      </div>
      <div className={`the_filter flex items-center justify-between pt-2 pb-2`}>
        <AddButton onClick={addNewFunction} title={title} />

        {onSessionPage ? (
          <div className={`flex flex-row items-center justify-center space-x-2`}>
            <p
              className={`flex items-center my-auto justify-center font-semibold text-xl text-primary w-fit`}
            >
              Filter by
            </p>

            <Select
              className={`w-[160px] text-center`}
              placeholder="Journey"
              allowClear
              dropdownStyle={{
                borderRadius: '8px',
              }}
              onChange={(value: string) => {
                handleChange(value, 'category');
              }}
              options={filteredByCategory}
            />
            <Select
              className={`w-[160px] text-center`}
              placeholder="Status"
              allowClear
              dropdownStyle={{
                borderRadius: '8px',
              }}
              onChange={(value: string) => {
                handleChange(value, 'status');
              }}
              options={filteredByStatus}
            />
          </div>
        ) : onAccessCodePage ? null : (
          <div className={`flex flex-row items-center justify-center space-x-4`}>
            <p
              className={`flex items-center my-auto justify-center font-semibold text-xl text-primary w-fit`}
            >
              Filter by
            </p>

            <Select
              className={`w-[200px] text-center`}
              placeholder={filterPlaceholder}
              allowClear
              dropdownStyle={{
                borderRadius: '8px',
              }}
              onChange={(value: string) => {
                handleChange(value, '');
              }}
              options={options}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PageHeader;
