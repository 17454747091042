import { AsyncThunk } from "@reduxjs/toolkit";
import { AnyAction } from "redux";

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

type PendingAction = ReturnType<GenericAsyncThunk["pending"]>;
type RejectedAction = ReturnType<GenericAsyncThunk["rejected"]>;
type FulfilledAction = ReturnType<GenericAsyncThunk["fulfilled"]>;

export const isPendingAction = (
  action: AnyAction,
  reducerType: string
): action is PendingAction => {
  return (
    action.type.startsWith(reducerType + "/") &&
    action.type.endsWith("/pending")
  );
};

export const isRejectAction = (
  action: AnyAction,
  reducerType: string
): action is RejectedAction => {
  return (
    action.type.startsWith(reducerType + "/") &&
    action.type.endsWith("/rejected")
    // && action.error.message !== "401"
  );
};

export const isFulfilledAction = (
  action: AnyAction,
  reducerType: string
): action is FulfilledAction => {
  return (
    action.type.startsWith(reducerType + "/") &&
    action.type.endsWith("/fulfilled")
  );
};
