import accountApi from '@apis/account.api';
import { db } from '@pages/private-chat/HandleAccessFirebase';
import { collection, DocumentData, onSnapshot, query, Timestamp, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';

interface UserInformationPrivateChatProps {
  userName?: string;
  body: string;
  time?: Timestamp;
  adminUnreadCount?: number;
  isClick: boolean;
}

const UserInformationPrivateChat = ({
  userName,
  body,
  time,
  isClick,
  adminUnreadCount,
}: UserInformationPrivateChatProps) => {
  const [getTime, setTime] = useState<string>('');
  const [countUnread, setCountUnread] = useState<number>(0);

  const transferTimeStamp = () => {
    let milliseconds = 0;
    if (time) {
      milliseconds = time.seconds * 1000 + time?.nanoseconds / 1e6;
    }
    const date = new Date(milliseconds);
    const dateString = date.toISOString().replace('T', ' ').slice(0, -1);
    const getTime = dateString.slice(11, 16);
    setTime(getTime);
  };
  useEffect(() => {
    setCountUnread(adminUnreadCount ? adminUnreadCount : 0);
  }, [adminUnreadCount]);

  useEffect(() => {
    if (time) {
      transferTimeStamp();
    }
  }, []);
  return (
    <div
      className={`mt-[20px] w-full p-[20px] border-2 rounded-lg ${
        isClick ? 'bg-[#F0F0F0] ' : ''
      }   h-[104px] flex flex-row`}
    >
      <div className=" flex flex-col w-full">
        <div className="flex flex-row m-0 justify-between w-full ">
          <p className="font-[20px] m-0  font-bold">{userName}</p>
          <p className="m-0">{getTime ? getTime : ''}</p>
        </div>
        <div className="flex flex-row h-full ">
          <p className="h-[48px] w-[96%]  line-clamp-2 pr-[10px] m-0">{body}</p>
          {countUnread !== 0 ? (
            <div className="h-full pt-[1%]">
              <div className="border rounded-full bg-[red] w-[24px] h-[24px] text-center text-[white] text-[12px] pt-[3px] ">
                {countUnread ? countUnread : 0}
              </div>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserInformationPrivateChat;
