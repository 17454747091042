import uploadApi from '@apis/upload.api';
import AddFileIcon from '@assets/images/icons/addCode.svg';
import Document from '@models/Document.model';
import { b64toBlob } from '@utils/b64toBlob.util';
import { errorCatchHandler } from '@utils/errorCatch.util';
import { getBase64 } from '@utils/getBase64.util';
import { FormInstance, Progress, message } from 'antd';
import { UploadProps } from 'antd/es/upload';
import Upload, { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload';
import React, { useEffect, useState } from 'react';
import './UploadFileForm.scss';

interface UploadFileProp {
  fileFieldName: string;
  fileFieldValueName: string;
  form: FormInstance;
  defaultFile?: string;
  defaultFileName?: string;
}

const UploadFileForm = ({
  fileFieldName,
  form,
  defaultFile,
  fileFieldValueName,
  defaultFileName,
}: UploadFileProp) => {
  const [fileAction, setFileAction] = useState<Document>();
  const [fileDetail, setFileDetail] = useState({});

  const [abortUpload, setAbortUpload] = useState(false);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [progressFile, setProgressFile] = useState(0);
  const [fileForm, setFileForm] = useState('');

  enum DocumentType {
    GeneralInformationImage = 'companyIndividualsFile ',
  }
  useEffect(() => {
    if (defaultFile && defaultFileName) {
      setFileForm(defaultFile);
      setFileDetail(defaultFileName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const uploadFile = async () => {
      const formDataFile = new FormData();
      if (fileAction && fileForm) {
        formDataFile.append('url', fileAction?.uploadUrl ?? '');
        formDataFile.append('file', b64toBlob(fileForm));
      }
      await uploadApi.upload(formDataFile);

      form.setFieldValue(fileFieldName, fileAction?.destination);
    };
    uploadFile();
  }, [fileForm, fileAction, fileFieldName, form]);

  const handleRequestModuleFile = async () => {
    try {
      const response = await uploadApi.requestUpload(DocumentType.GeneralInformationImage);
      if (response.data.success) {
        setFileAction(response.data.data);
      }
      return response.data.data;
    } catch (error) {
      errorCatchHandler(error);
    }
  };

  const beforeUploadFile = async (file: RcFile) => {
    if (file.type === 'text/csv') {
      const fileUrl = (await handleRequestModuleFile()) as Document;
      const isFileCheck = file.type === 'text/csv';

      const fileSize = file.size / 1024 / 1024 < 1;

      if (!isFileCheck) {
        message.error('You can only upload csv');
      }

      if (!fileSize) {
        message.error('Your file should be up to 1 MB at maximum');
      }
      if (abortUpload) {
        setAbortUpload(false);
      }
      setFileAction(fileUrl);

      return isFileCheck && fileSize;
    } else {
      const isFileCheck = file.type === 'text/csv';

      if (!isFileCheck) {
        message.error('You can only upload csv');
      }

      return isFileCheck;
    }
  };

  const handleUploadFile: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (abortUpload === true) {
      info.file.status = 'error';
      setIsLoadingFile(false);
      setProgressFile(0);
      setAbortUpload(true);

      return;
    }

    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setFileForm(url);
        form.setFieldValue(fileFieldValueName, info.file.name);
        if (defaultFileName) {
          form.setFieldValue(fileFieldValueName, defaultFileName);
        }
      });
      setFileDetail(info.file.name);
      setIsLoadingFile(false);
      setProgressFile(0);
    } else if (info.file.status === 'error') {
      setIsLoadingFile(false);
      setProgressFile(0);
    } else if (info.file.status === undefined) {
      setIsLoadingFile(false);
      setProgressFile(0);
      setAbortUpload(false);
    } else {
      const percent = info.file.percent;
      if (percent) {
        setProgressFile(Math.round(percent));
      }
      setIsLoadingFile(true);
      setAbortUpload(false);
    }
  };
  const handleClose = (e: React.MouseEvent<HTMLSpanElement>) => {
    setFileForm('');
    form.setFieldValue(fileFieldName, undefined);
    form.setFieldValue(fileFieldValueName, '');
    e.stopPropagation();
  };

  const uploadFileButton = (
    <div
      className={`upload-file-image w-full 
                    bg-transparent h-fit`}
    >
      <div className={`mb-[15px] text-center text-primary `}>
        <p>Add a file that includes list of email addresses for all eligible users</p>
        <p>The file should be in .csv format</p>
      </div>

      <button
        type="button"
        className=" flex-row mb-[20px]  w-full h-[44px] rounded-[8px] pt-[8px] text-[#FFFFFF] flex justify-center  
                    disabled:brightness-[150%] disabled:cursor-not-allowed hover:brightness-[150%]
                  bg-primary cursor-pointer"
      >
        <img alt="img" src={AddFileIcon} className={`mr-[5px]`} />
        <p className={` pt-[3px]`}>Add File</p>
      </button>
    </div>
  );
  return (
    <div className={`w-full h-fit flex gap-4 flex-col`}>
      <Upload
        name="module-thumbnail"
        onChange={handleUploadFile}
        maxCount={1}
        accept=".csv"
        beforeUpload={beforeUploadFile}
        method="PUT"
        action={fileAction?.uploadUrl}
        showUploadList={false}
        className={`w-full h-fit p-8 cursor-pointer flex items-center
        justify-center border-primary border-dashed rounded-md`}
      >
        <div className={`w-full flex justify-center`}>
          {isLoadingFile || progressFile > 0 ? (
            <div className={`w-full flex justify-center`}>
              <Progress
                className={`w-full flex justify-center`}
                percent={progressFile}
                type="circle"
                strokeColor={{
                  '0%': '#4D4479',
                  '20%': '#2D5E98',
                  '80%': '#00A7AC',
                  '100%': '#36b569',
                }}
              />
            </div>
          ) : (
            <div className={`w-full flex justify-center`}>
              {fileForm ? (
                <div className={`relative`}>
                  <div
                    className={` border-solid border-primary rounded-lg  w-[600px] h-[90px] flex justify-center truncate ... `}
                  >
                    <a href={fileForm} className={`m-auto`}>
                      {fileDetail}
                    </a>
                  </div>
                  <div
                    className={` border-solid border-primary rounded-full w-8 h-8 flex justify-center  absolute
                            top-[-15px] right-[-14px] bg-primary text-white z-40`}
                    onClick={(e) => handleClose(e)}
                  >
                    <div className={`m-auto`}>X</div>
                  </div>
                </div>
              ) : (
                <div className={`w-full h-fit flex`}>{uploadFileButton}</div>
              )}
            </div>
          )}
          {/* <div className={`w-full`}>{}</div> */}
        </div>
      </Upload>
    </div>
  );
};
export default UploadFileForm;
