import { CloseCircleOutlined, DownOutlined } from '@ant-design/icons';
import affirmationApi from '@apis/affirmation.api';
import Affirmation from '@models/Affirmation.model';
import { reasons } from '@utils/reasons.util';
import { Button, Form, Input, Modal, Select, SelectProps, Tag, message } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { useCallback, useEffect, useState } from 'react';
import '../Affirmation.style.scss';

const tagRender = (props: CustomTagProps) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      className={`flex m-[6px] rounded-[5px] h-[30px] border-[#F0F0F0] border-solid border-[1px] items-center justify-center`}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
    >
      <span className={`flex items-center justify-center text-black font-normal`}>{label}</span>
    </Tag>
  );
};

interface CreateAffirmationModalProps {
  model: string;
  onOpen: boolean;
  onClose?: () => void;
  affirmationId?: string;
  onModalOk?: () => void;
  onEditOk?: () => void;
}

const CreateAffirmationModal = ({
  onOpen,
  onClose = () => {},
  model,
  affirmationId,
  onModalOk = () => {},
  onEditOk = () => {},
}: CreateAffirmationModalProps) => {
  const [form] = Form.useForm();
  const [affirmation, setAffirmation] = useState<Affirmation>();
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const sortedReasons = reasons.sort((a, b) => a.label.localeCompare(b.label));
  const options: SelectProps['options'] = sortedReasons;

  // const handleChange = (value: string[]) => {
  //
  // };

  const handleSubmit = async () => {
    try {
      setIsAdding(true);
      const result = {
        statement: form.getFieldValue('statement').trim(),
        tags: form.getFieldValue('tags'),
      };
      await affirmationApi.createAffirmation(result);
      message.success('Your new affirmation has been saved');
      onModalOk();
      onClose();
    } catch (error) {
      message.error('Error when submit your data');
    } finally {
      setIsAdding(false);
    }
  };

  const handleEditAffirmation = async () => {
    if (affirmationId) {
      setIsEditing(true);
      const result = {
        statement: form.getFieldValue('statement').trim(),
        tags: form.getFieldValue('tags'),
      };
      try {
        await affirmationApi.editAffirmation(affirmationId, result);
        message.success('This affirmation has been updated successfully');
        onEditOk();
        onClose();
      } catch (error) {
      } finally {
        setIsEditing(false);
      }
    }
  };

  const getAffirmationDetail = useCallback(async () => {
    if (affirmationId) {
      const response = await affirmationApi.getAffirmation(affirmationId);
      if (response.data.success) {
        setAffirmation(response.data.data);
      }
    }
  }, [affirmationId]);
  useEffect(() => {
    if (onOpen) {
      getAffirmationDetail();
    }
  }, [onOpen, getAffirmationDetail]);

  useEffect(() => {
    form.setFieldsValue(affirmation);
  }, [form, affirmation]);

  return (
    <>
      <Modal
        centered
        destroyOnClose
        open={onOpen}
        onOk={onClose}
        onCancel={onClose}
        className={`affirmation-modal`}
        bodyStyle={{ backgroundColor: '#4D4479' }}
        title={
          <h2
            style={{
              fontWeight: 700,
              fontSize: 32,
              color: 'white',
              textAlign: 'center',
              marginTop: 40,
            }}
          >
            {model === 'edit' ? 'Edit Affirmation' : 'Add Affirmation'}
          </h2>
        }
        width={728}
        closeIcon={<CloseCircleOutlined className={`text-white text-[20px] pb-2`} />}
        footer={null}
      >
        <div className={`flex flex-col p-10`}>
          <Form
            layout="vertical"
            form={form}
            initialValues={{
              ...affirmation,
              tags: _isEmpty(affirmation) ? [] : [affirmation?.tags],
            }}
            onReset={onClose}
          >
            <Form.Item
              className="create-affirmation-modal"
              label={
                <p className={`text-white text-base`}>
                  Affirmation<span className={`text-[#DB555A]`}>*</span>
                </p>
              }
              name="statement"
            >
              <Input
                className={`affirmation-input`}
                placeholder="Your affirmation"
                maxLength={50}
                showCount
              />
            </Form.Item>

            <Form.Item
              label={
                <p className={`text-white text-base`}>
                  Reasons<span className={`text-[#DB555A]`}>*</span>
                </p>
              }
              name="tags"
            >
              <Select
                allowClear
                mode="multiple"
                placeholder="Please select reasons"
                options={options}
                tagRender={tagRender}
                dropdownStyle={{
                  borderRadius: 10,
                }}
                suffixIcon={<DownOutlined />}
              />
            </Form.Item>
          </Form>
          {model === 'edit' && !isEditing ? (
            <Button
              onClick={handleEditAffirmation}
              htmlType="submit"
              className={`w-full h-[60px] rounded-[10px] bg-[#8B90C6] border-none`}
              type="primary"
            >
              Save
            </Button>
          ) : (
            model === 'edit' && (
              <Button
                loading={isEditing}
                disabled={isEditing}
                htmlType="submit"
                className={`w-full h-[60px] rounded-[10px] bg-[#8B90C6] border-none`}
                type="primary"
              >
                Saving
              </Button>
            )
          )}
          {model !== 'edit' && !isAdding ? (
            <div className={`flex justify-center`}>
              <Button
                onClick={handleSubmit}
                htmlType="submit"
                className={`w-full h-[60px] rounded-[10px] bg-[#8B90C6] border-none`}
                type="primary"
              >
                Save
              </Button>
            </div>
          ) : (
            model !== 'edit' && (
              <div className={`flex justify-center`}>
                <Button
                  loading={isAdding}
                  disabled={isAdding}
                  htmlType="submit"
                  className={`w-full h-[60px] rounded-[10px] bg-[#8B90C6] border-none`}
                  type="primary"
                >
                  Saving
                </Button>
              </div>
            )
          )}
        </div>
      </Modal>
    </>
  );
};

export default CreateAffirmationModal;
