import axios from "axios";

const guestClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    Accept: "application/json",
  },
  timeout: 60000,
});

export default guestClient;
