import PageHeader from '@components/PageHeader';
import routes from '@routes';
import { accessCodeTypes } from '@utils/accessCodeType.util';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ViewAccessCodeClinic from './components/view-access-code/ViewAccessCodeClinic';
import ViewAccessCodeCompany from './components/view-access-code/ViewAccessCodeCompany';

const AccessCodePage = () => {
  const navigate = useNavigate();
  const accessCodeType = useParams().accessCodeType;
  useEffect(() => {
    if (accessCodeType !== 'clinic' && accessCodeType !== 'company')
      navigate(routes.AccessCode.path + 'company');
  }, [accessCodeType, navigate]);

  return (
    <div className={`flex flex-col gap-6 w-full h-full overflow-auto p-[40px]`}>
      <PageHeader
        title="Add access permission"
        onAccessCodePage
        addNewFunction={() => {
          navigate(routes.AddAccessCode.path);
        }}
      />
      <div
        className={`w-full h-fit 
      flex flex-col gap-6 items-center`}
      >
        <div className={`w-full h-fit flex flex-row gap-4 justify-between items-center`}>
          {accessCodeTypes.map((type) => {
            return (
              <button
                type="button"
                key={type.value}
                className={`h-fit py-2 text-center items-center rounded-md
              flex-1 text-lg cursor-pointer duration-100 ${
                type.value !== accessCodeType
                  ? `border-primary border-[1px] border-solid
                  text-primary opacity-50`
                  : `text-white border-0`
              }`}
                onClick={() => navigate(routes.AccessCode.path + type.value)}
                style={{
                  backgroundColor: type.value !== accessCodeType ? 'transparent' : type.color,
                }}
              >
                {type.label}
              </button>
            );
          })}
        </div>

        <div className={`view-access-permission w-full h-fit`}>
          {accessCodeType === accessCodeTypes[0].value && <ViewAccessCodeCompany />}
          {accessCodeType === accessCodeTypes[1].value && <ViewAccessCodeClinic />}
        </div>
      </div>
    </div>
  );
};
export default AccessCodePage;
