import { LeftOutlined } from '@ant-design/icons';
import routes from '@routes';
import { useNavigate, useParams } from 'react-router-dom';
import './UserProfileDetail.styles.scss';
import { useState } from 'react';
import UserOrderPage from './UserProfileDetail/UserOrder';
import UserProfileDiagnostic from './UserProfileDetail/UserProfileDiagnostic';

export const dataType = [
  {
    title: 'Order Details',
    value: 'orderDetails',
  },
  {
    title: 'Diagnostic results',
    value: 'diagnosticResults',
  },
];

export interface OrderType {
  id: string;
  userName: string;
  requestDate: string;
  currency: string;
  authorizingProvider: string;
  authorizingDate: string;
  requestStatus: string;
  orderStatus: string;
}

export interface DiagnosticType {
  mode: string;
  updated: string;
}

export interface OrderDetailType {
  type: string;
  subject: string[];
  dose: string[];
  qty: number[];
  price: number[];
}

export const diagnosticData: DiagnosticType[] = [
  {
    mode: 'Automatic',
    updated: '2024-07-30T14:32:00Z',
  },
  {
    mode: 'Manual',
    updated: '2024-07-29T09:45:00Z',
  },
  {
    mode: 'Scheduled',
    updated: '2024-07-31T08:00:00Z',
  },
  {
    mode: 'On-Demand',
    updated: '2024-07-28T17:15:00Z',
  },
  {
    mode: 'Emergency',
    updated: '2024-07-31T21:00:00Z',
  },
];
export const orderData: OrderType[] = [
  {
    id: '123abc',
    userName: 'john_doe',
    requestDate: '2024-07-30T10:15:30Z',
    currency: 'USD',
    authorizingProvider: 'Bank of America',
    authorizingDate: '2024-07-31T09:45:00Z',
    requestStatus: 'Pending',
    orderStatus: 'Incomplete',
  },
  {
    id: '456def',
    userName: 'jane_smith',
    requestDate: '2024-07-29T14:25:00Z',
    currency: 'EUR',
    authorizingProvider: 'Deutsche Bank',
    authorizingDate: '2024-07-30T08:00:00Z',
    requestStatus: 'Approved',
    orderStatus: 'Incomplete',
  },
  {
    id: '789ghi',
    userName: 'alice_jones',
    requestDate: '2024-07-28T11:10:00Z',
    currency: 'GBP',
    authorizingProvider: 'HSBC',
    authorizingDate: '2024-07-28T12:30:00Z',
    requestStatus: 'Rejected',
    orderStatus: 'Incomplete',
  },
  {
    id: '101jkl',
    userName: 'michael_brown',
    requestDate: '2024-07-27T13:30:00Z',
    currency: 'CAD',
    authorizingProvider: 'Royal Bank of Canada',
    authorizingDate: '2024-07-28T09:00:00Z',
    requestStatus: 'Pending',
    orderStatus: 'Incomplete',
  },
  {
    id: '102mno',
    userName: 'emma_wilson',
    requestDate: '2024-07-26T16:45:00Z',
    currency: 'AUD',
    authorizingProvider: 'Westpac',
    authorizingDate: '2024-07-27T10:15:00Z',
    requestStatus: 'Approved',
    orderStatus: 'Complete',
  },
  {
    id: '103pqr',
    userName: 'oliver_clark',
    requestDate: '2024-07-25T18:00:00Z',
    currency: 'JPY',
    authorizingProvider: 'Mitsubishi UFJ Financial Group',
    authorizingDate: '2024-07-26T08:45:00Z',
    requestStatus: 'Rejected',
    orderStatus: 'Complete',
  },
  {
    id: '104stu',
    userName: 'sophia_martin',
    requestDate: '2024-07-24T20:15:00Z',
    currency: 'CHF',
    authorizingProvider: 'Credit Suisse',
    authorizingDate: '2024-07-25T07:30:00Z',
    requestStatus: 'Pending',
    orderStatus: 'Complete',
  },
];

const UserProfileDetail = () => {
  const navigate = useNavigate();
  const { userName } = useParams();
  const [active, setActive] = useState<string>('orderDetails');
  const [firstView, setFirstView] = useState<boolean>(true);

  const handleClick = (value: string) => {
    setActive(value);
    if (firstView) {
      setFirstView(false);
    }
  };

  return (
    <div className="p-[40px] h-full overflow-y-auto ">
      <div className={`flex flex-row justify-left items-center gap-8`}>
        <LeftOutlined
          className={`text-primary text-[22px]`}
          onClick={() => {
            navigate(routes.UserProfile.path);
          }}
        />
        <div className={`text-3xl text-primary font-bold flex flex-row`}>
          UserProfile <div className="font-normal">/{userName}</div>
        </div>
      </div>
      {/* <UserDetail userName={userName ?? ''} /> */}
      <div>
        <div className="flex flex-row w-full mt-[40px]">
          {dataType.map((data) => (
            <div
              className={`${
                data.value === active ? 'text-primary' : 'text-[#919191]'
              } text-[20px] font-bold w-fit h-[42px] mr-[16px] cursor-pointer`}
              onClick={() => handleClick(data.value)}
            >
              {data.title}
              {data.value === active ? (
                <div
                  className={`w-full h-[5px]  bg-primary underline-container ${
                    !firstView
                      ? data.value === dataType[0].value
                        ? 'slide-left'
                        : 'slide-right'
                      : ''
                  }  mt-[10px]`}
                ></div>
              ) : (
                <div />
              )}
            </div>
          ))}
        </div>
        <div className="">
          {active === dataType[0].value ? (
            <UserOrderPage data={orderData} />
          ) : (
            <UserProfileDiagnostic data={diagnosticData} />
          )}
        </div>
      </div>
    </div>
  );
};
export default UserProfileDetail;
