import { title } from "process";

const routes = {
  Dashboard: { path: '/' },
  Affirmation: { path: '/affirmations', title: 'Affirmations' },
  DropPost: { path: '/drops/:dropId/posts', title: 'All Posts' },
  DropPostDetail: {
    path: '/drops/:dropId/posts/:postId',
    title: 'Post Detail',
  },
  Report: { path: '/reports', title: 'Report' },
  ReportDetail: { path: '/reports/:reportId', title: 'Report Detail' },
  DailyVibe: { path: '/daily-vibes', title: 'Daily vibe' },
  FeedBackDetail: { path: '/feedbacks/:feedbackId', title: 'Feedback Detail' },
  SignIn: { path: '/login' },
  SignUp: { path: '/sign-up' },
  ForgotPassword: { path: '/forgot-password' },
  ResetPassword: { path: '/reset-password' },
  NotFound: { path: '/404' },
  ServerError: { path: '/500' },
  // Session: { path: '/session', title: 'Session' },
  // AddSession: { path: '/session/add', title: 'Add Session' },
  // SessionDetail: { path: '/session/:sessionId', title: 'Session Detail' },
  // EditSession: { path: '/session/edit/:sessionId', title: 'Edit Session' },
  // Navigator: { path: '/navigator', title: 'Navigator' },
  AccessCode: {
    path: '/access-code/',
    fullPath: '/access-code/:accessCodeType',
    title: 'Access permission',
  },
  AddAccessCode: {
    path: '/access-code/add',
    title: 'Add Access Code',
  },

  AccessForWholeCompany: {
    path: '/access-code/add/company',
    title: 'Access Code For Whole Company',
  },

  EditAccessForWholeCompany: {
    path: '/access-code/edit/company/:companyId',
    prePath: '/access-code/edit/company/',
    title: 'Access Code For Whole Company',
  },

  AccessForCompanyEmployee: {
    path: '/access-code/add/employee',
    title: 'Access Code For Individuals of Companies',
  },

  EditAccessForCompanyEmployee: {
    path: '/access-code/edit/employee/:employeeId',
    prePath: '/access-code/edit/employee/',
    title: 'Access Code For Individuals of Companies',
  },

  AccessForPatient: {
    path: '/access-code/add/patients',
    title: 'Access Code For Patients of Clinics',
  },

  EditAccessForPatient: {
    path: '/access-code/edit/patients/:patientsId',
    prePath: '/access-code/edit/patients/',
    title: 'Access Code For Patients of Clinics',
  },

  AccessForFollowers: {
    path: '/access-code/add/influencer',
    title: 'Access Code For Followers of Influencer',
  },

  EditAccessForFollowers: {
    path: '/access-code/edit/influencer/:influencerId',
    prePath: '/access-code/edit/influencer/',
    title: 'Access Code For Followers of Influencer',
  },
  EditAccessCode: {
    path: '/access-code/edit/all/:id',
    prePath: '/access-code/edit/all/',
    title: 'Edit AccessCode',
  },
  // FeedbackPage: {
  //   path: '/feedback',
  //   title: 'Feedback',
  // },
  Playground: { path: '/playground', title: 'Play' },
  // Telehealth: { path: '/telehealth', title: 'Telehealth' },
  PrivateChat: { path: '/privateChat', title: 'Private Chat' },
  UserRating: {path:'/userRating', title:'User Rating'},
  // TelehealthDiagnostic: {
  //   path: '/telehealth/diagnostic/:userId/:diagnosticType/:pageNumber',
  //   prePath: '/telehealth/diagnostic/',
  //   title: 'Ana Content Management Dashboard',
  // },
  // // TelehealthDiagnostic: {
  // //   path: '/telehealth/diagnostic/:userId/:diagnosticType/:pageNumber',
  // //   prePath: '/telehealth/diagnostic/',
  // //   title: 'Ana Content Management Dashboard',
  // // },
  UserProfile:{ path: '/user-profile', title:'User Profile'},
  UserProfileDetail:{path:'/user-profile/detail/:userId', title:'User Profile Detail'},
  UserOrderDetail:{path:'/user-profile/detail/:userId/order-detail', title:'Order Detail'},
  UserDiagnosticDetail:{path:'/user-profile/detail/:userId/diagnostic-detail', title:'Diagnostic Detail'},
  ListOfOrder:{path:'/listOrder', title:' List Of Order'}
};

export default routes;
