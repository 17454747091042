
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PrivateChatState } from "./chat.state";




const initialState: PrivateChatState = {
    unreadMessage:0,
    firstLoading: true,
    currentChatItem:'',
    limitItem:15,
    limitList:15,
    subscriptionExpired: false,
};

const privateChatReducer = createSlice({
  name: 'privateChat',
  initialState,
  reducers: {
    addUnReadMessage(state, action: PayloadAction<PrivateChatState>) {
      state.unreadMessage =  action.payload.unreadMessage
    },
    minusReadMessage(state, action: PayloadAction<PrivateChatState>) {
        state.unreadMessage = state.unreadMessage - action.payload.unreadMessage
    },
    addFirstLoading(state, action: PayloadAction<PrivateChatState>){
      state.firstLoading = action.payload.firstLoading
    },
    addCurrentChatItem(state, action: PayloadAction<PrivateChatState>){
      state.currentChatItem = action.payload.currentChatItem
    },
    removeCurrentChatItem(state){
      state.currentChatItem = ''
    },
    addLimitItem(state, action: PayloadAction<PrivateChatState>){
      state.limitItem = action.payload.limitItem
    },
    addLimitList(state, action: PayloadAction<PrivateChatState>){
      state.limitList = action.payload.limitList
    },
    setSubscriptionExpired(state, action: PayloadAction<PrivateChatState>){
      state.subscriptionExpired = action.payload.subscriptionExpired
    }
  }
});

export const { addUnReadMessage, minusReadMessage, addFirstLoading, addCurrentChatItem, addLimitItem,  addLimitList, setSubscriptionExpired} = privateChatReducer.actions;
export default privateChatReducer.reducer;