import loggedClient from '../https/loggedClient.http';

export type AccessCodeSortingType = '' | 'company' | 'individual' | 'clinicName';

export interface AccessCodeSortingInformation {
  by: AccessCodeSortingType;
  order: 'asc' | 'desc' | '';
  isActive: boolean;
}

const baseAccessCode = '/admin/accessPermission';

const accessCodeApi = {
  getAccessCode: (
    pageNumber: number,
    pageSize: number,
    filteredBy: 'company' | 'clinic',
    sortedBy?: AccessCodeSortingType,
    sortingDirection?: 'asc' | 'desc' | ''
  ) =>
    loggedClient.get(`${baseAccessCode}s`, {
      params: { pageNumber, pageSize, filteredBy, sortedBy, sortingDirection },
    }),
  createAccessCodeForCompany: (data: any) =>
    loggedClient.post(`${baseAccessCode}s/wholeCompany`, data),
  createAccessCodeForPatientsOfaClinic: (data: any) =>
    loggedClient.post(`${baseAccessCode}s/clinic`, data),
  createAccessCodeForCompanyIndividuals: (data: any) =>
    loggedClient.post(`${baseAccessCode}/companyIndividuals`, data),
  getDetailAccessCode: (accessPermissionId: string) =>
    loggedClient.get(`${baseAccessCode}s/${accessPermissionId}`),
  editAccessCodeForCompany: (accessPermissionId: string, data: any) =>
    loggedClient.put(`${baseAccessCode}s/wholeCompany/${accessPermissionId}`, data),
  editAccessCodeForClinic: (accessPermissionId: string, data: any) =>
    loggedClient.put(`${baseAccessCode}s/clinic/${accessPermissionId}`, data),
  deleteAccessCode: (accessCodeId: string) =>
    loggedClient.delete(`${baseAccessCode}s/${accessCodeId}`),
  editAccessCodeForCompanyIndividual: (accessCodeId: string, data: any) =>
    loggedClient.put(`${baseAccessCode}s/companyIndividuals/${accessCodeId}`, data),
  editAccessCodeForWholeCompany: (accessCodeId: string, data: any) =>
    loggedClient.put(`${baseAccessCode}s/wholeCompany/${accessCodeId}`, data),
  getDetailAccessForWholeCompany: (accessCodeId: string) =>
    loggedClient.get(`${baseAccessCode}s/${accessCodeId}`),
};

export default accessCodeApi;
