import { message, Table } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import './UserProfile.styles.scss';
import { useNavigate } from 'react-router-dom';
import SearchButton from '@components/SearchButton';
import accountApi from '@apis/account.api';
import { useEffect, useState } from 'react';
import UserDetail from '@models/UserDetail.model';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { handleSetAnaProgramCurrentPage } from '@state/ana-program/handleSetStateAnaProgram/handleSetStateAnaProgram';
import { capitalizeFirstLetter } from '@utils/capitalFirstLetter.util';
import UserDetailModel from '@models/UserDetail.model';
import { handleGetSortDirection } from '@components/SortList';
import { handleSetCurrentPageUserProfile } from '@state/user-profile/handleSetStateUserProfile/handleSetStateUserProfile';
import { useSelector } from 'react-redux';
import { RootState } from '@state/rootReducer';

export const pagination = {
  pageNumber: 1,
  pageSize: 10,
};

const UserProfilePage = () => {
  const navigate = useNavigate();
  const [listUser, setListUser] = useState<UserDetailModel[]>([]);
  const [page, setPage] = useState<number>(
    useSelector((state: RootState) => state.userProfile.currentPage) ?? pagination.pageNumber
  );
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const getListUser = async (pageNumber: number) => {
    try {
      setLoading(true);
      const response = await accountApi.getListUser(pageNumber, pagination.pageSize);
      if (response.data.success) {
        setListUser(response.data.data.users);
        setTotalCount(response.data.data.totalCount);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getListUser(page);
  }, [page]);

  const handleSearch = async (value: string) => {
    const searchResponse = await accountApi.searchUserList(1, 10, value);
    if (searchResponse.status === 200) {
      setListUser(searchResponse.data.data.users);
      setTotalCount(searchResponse.data.data.totalCount);
    }
  };

  const handleSortListUser = async (
    sortType: string | undefined,
    sortField: string,
    pageNumber: number
  ) => {
    try {
      const sortDirection = handleGetSortDirection(sortType);
      const listUserResponse = await accountApi.sortUserList(
        pageNumber,
        pagination.pageSize,
        sortField,
        sortDirection
      );
      if (listUserResponse.status === 200) {
        setListUser(listUserResponse.data.data.users);
        setTotalCount(listUserResponse.data.data.totalCount);
        setLoading(false);
      }
    } catch (error) {
      message.error('something wrong');
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<UserDetail> | SorterResult<UserDetail>[],
    extra: TableCurrentDataSource<UserDetail>
  ) => {
    const isSorter = Array.isArray(sorter) ? sorter[0].column : sorter.column;
    setPage(pagination.current ? pagination.current : 1);
    handleSetCurrentPageUserProfile(pagination.current ? pagination.current : 1);
    if (isSorter?.title) {
      setLoading(true);
      const sortOrder = Array.isArray(sorter) ? sorter[0].order : sorter.order;
      const sortField = Array.isArray(sorter) ? sorter[0].field : sorter.field;
      const capitalizedSortField = capitalizeFirstLetter(sortField ? sortField.toString() : '');
      handleSortListUser(
        sortOrder ? sortOrder : undefined,
        capitalizedSortField,
        pagination.current ? pagination.current : 1
      );
    } else {
      getListUser(pagination.current ? pagination.current : 1);
      handleSetAnaProgramCurrentPage(page);
    }
  };
  const columns: ColumnsType<UserDetail> = [
    {
      title: 'No.',
      className: 'column-first-item',
      width: '4%',
      key: 'id',
      render: (userDetail: UserDetail) =>
        page * pagination.pageSize - pagination.pageSize + listUser.indexOf(userDetail) + 1,
    },
    {
      title: 'User Name',
      width: '18%',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (name) => {
        return <p className="m-0 p-0 line-clamp-1 w-[260px]">{name}</p>;
      },
    },
    {
      title: 'Email',
      width: '26%',
      key: 'email',
      dataIndex: 'email',
      sorter: true,
    },
    {
      title: 'Phone',
      width: '18%',
      key: 'phoneNumber',
      dataIndex: 'phoneNumber',
      sorter: true,
    },
    {
      title: 'Address',
      width: '26%',
      dataIndex: 'address',
      key: 'address',
      sorter: true,
    },
    {
      title: 'View',
      width: '8%',
      dataIndex: 'id',
      className: 'column-last-item',
      key: 'id',
      render: (id) => {
        return (
          <p
            className=" text-[16px] text-[#2F6BFF] hover:underline  decoration-solid hover:text-[#5384ff] cursor-pointer"
            onClick={() => {
              // navigate(`/user-profile/detail/${index}`);
              navigate(`/user-profile/detail/${id}/diagnostic-detail`);
            }}
          >
            View
          </p>
        );
      },
    },
  ];

  return (
    <div className="p-[40px] h-full overflow-auto">
      <div className=" flex flex-row justify-between w-full">
        <p className="text-3xl text-primary font-bold w-[300px] mt-[10px]">User's Profile</p>
        <SearchButton onClick={handleSearch} />
      </div>
      <div>
        <Table
          loading={loading}
          className="table-user-profile"
          columns={columns}
          dataSource={listUser}
          onChange={handleTableChange}
          pagination={{
            position: ['bottomCenter'],
            pageSize: pagination.pageSize,
            total: totalCount,
            showSizeChanger: false,
            defaultCurrent: page,
          }}
        />
      </div>
    </div>
  );
};
export default UserProfilePage;
