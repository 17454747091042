import './SkeletonDot.scss'; // Import the CSS file for styling

const SkeletonDot = () => {
  return (
    <div className={`skeleton-container`}>
      {/* Skeleton dots */}
      <div className={`dot dot-1`}></div>
      <div className={`dot dot-2`}></div>
      <div className={`dot dot-3`}></div>
    </div>
  );
};

export default SkeletonDot;
