import loggedClient from '../https/loggedClient.http';

const baseDailyVibe = '/admin/diagnostics';

const diagnosticApi = {
  getDiagnostic: () => loggedClient.get(baseDailyVibe),
  getDiagnosticByUserId: (userId: string) => loggedClient.get(`${baseDailyVibe}/multiUsers/users/${userId}`),
  getDiagnosticDetail: (diagnosticMultiUserId: string) => loggedClient.get(`${baseDailyVibe}/multiUsers/${diagnosticMultiUserId}`),
};

export default diagnosticApi;
