export function b64toBlob(dataURI: string) {
  const parts = dataURI.split(';base64,');

  const mediaType = parts[0].split(':')[1];

  const byteString = window.atob(parts[1]);

  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mediaType });
}
