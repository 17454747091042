import accountApi from '@apis/account.api';
import routes from '@routes';
import { Button, Form, Input } from 'antd';
import { Auth } from 'aws-amplify';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/icons/logo-ana.svg';
import AccountLogin from '../../models/AccountLogin.model';
import alertUtil from '../../utils/alert.util';
import { jwtDecode } from 'jwt-decode';
import './Login.styles.scss';
import clsx from 'clsx';
interface DecodedToken {
  user_id: string;
}
const LoginPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const currentSession = useCallback(async () => {
    const isAuth = (await Auth.currentSession()).getIdToken().getJwtToken();
    if (isAuth && isAuth !== undefined) {
      navigate(routes.Affirmation.path);
    }
  }, [navigate]);

  useEffect(() => {
    currentSession();
  }, [currentSession]);

  const onLogin = async (values: AccountLogin) => {
    try {
      setIsLoading(true);
      const response = await accountApi.login(values);
      if (response) {
        const getIdToken = (await Auth.currentSession()).getIdToken().getJwtToken();
        const decodeAccessCode = jwtDecode<DecodedToken>(getIdToken);
        localStorage.setItem('currentUserId', decodeAccessCode.user_id);
      }
      navigate(routes.Affirmation.path);
    } catch (error) {
      setIsLoading(false);
      alertUtil.error('Invalid', 'Invalid email or password');
    }
  };
  return (
    <div id="login-form-container">
      <img src={logo} alt="logo" className={`login-logo`} />

      <Form name="normal_login" className={`login-form bg-white`} onFinish={onLogin}>
        <div className={`login-form-header`}>
          <div className={`font-bold text-primary text-2xl mb-4 custom-login-text`}>Login</div>
          <div>
            <p className={`font-semibold`} style={{ fontSize: 16 }}>
              Welcome back. Input your details to pickup where you left off.
            </p>
          </div>
        </div>

        <div className={`login-form-body`}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: 'The input is not valid E-mail',
                type: 'email',
              },
            ]}
          >
            <Input placeholder="Email" className={`custom-input`} />
          </Form.Item>

          <Form.Item name="password" rules={[{ required: true, message: 'Password is required' }]}>
            <Input.Password placeholder="Password" className={`custom-input h-[56px]`} />
          </Form.Item>
        </div>

        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          disabled={isLoading}
          className={clsx('login-form-button bg-primary custom-btn-login', {
            'border-none': isLoading,
            'hover:cursor-not-allowed': isLoading,
          })}
        >
          {isLoading ? 'Logging in' : 'Login'}
        </Button>
      </Form>
    </div>
  );
};

export default LoginPage;
