export function wait(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function generateCode(): Promise<string> {
  let code = '';
  const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

  for (let i = 0; i < 9; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    const randomChar = characters.charAt(randomIndex);
    code += randomChar;
  }

  return code;
}

export function formatAccessCode(input: string): string {
  const formattedString = input.replace(
    /([a-zA-Z0-9]{3})([a-zA-Z0-9]{3})([a-zA-Z0-9]{3})/,
    '$1-$2-$3'
  );
  return formattedString;
}

export function mergeAccessCode(code1: string, code2: string, index: number): string {
  const part1 = code1.slice(0, index + 1);
  const part2 = code2.slice(index + 1);
  return part1 + part2;
}
