
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserState } from "./user.state";


const initialState: UserState = {
  id: '',
  userName: '',
  email: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUserProfile(state, action: PayloadAction<UserState>) {
      state.id = action.payload.id;
      state.userName = action.payload.userName;
      state.email = action.payload.email;
    },
    resetUserProfile() {
      return {
        id: '',
        userName: '',
        email: '',
      };
    },
   
  }
});

export const { addUserProfile, resetUserProfile } = userSlice.actions;
export default userSlice.reducer;