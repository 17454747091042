import OptionType from '@models/Option.model';

export const reasons: OptionType[] = [
  {
    value: 'cravings',
    label: 'Cravings',
  },
  {
    value: 'burnout',
    label: 'Burnout',
  },
  {
    value: 'cantSleep',
    label: 'Cant Sleep',
  },
  {
    value: 'hormonalMoodiness',
    label: 'Hormonal Moodiness',
  },
  {
    value: 'bodyImage',
    label: 'Body Image',
  },
  {
    value: 'workStress',
    label: 'Work Stress',
  },
  {
    value: 'hormonalTreatments',
    label: 'Hormonal Treatments',
  },
  {
    value: 'coupleStress',
    label: 'Couple Stress',
  },
  {
    value: 'fertilityStress',
    label: 'Fertility Stress',
  },
  {
    value: 'waitingForPregnancyTest',
    label: 'Waiting for Pregnancy Test',
  },
  {
    value: 'negativePregnancyTest',
    label: 'Negative Pregnancy Test',
  },
  {
    value: 'infertilityGuiltOrShame',
    label: 'Infertility Guilt/Shame',
  },
  {
    value: 'relationships',
    label: 'Relationships',
  },
  {
    value: 'family',
    label: 'Family',
  },
  {
    value: 'myPartner',
    label: 'My Partner',
  },
  {
    value: 'goodWorkout',
    label: 'Good Workout',
  },
  {
    value: 'workWins',
    label: 'Work wins',
  },
  {
    value: 'feelingHealthy',
    label: 'Feeling healthy',
  },
  {
    value: 'pregnancyTest',
    label: 'Pregnancy Test',
  },
  {
    value: 'hopeForABaby',
    label: 'Hope For A Baby',
  },
  {
    value: 'infertilityResilience',
    label: 'Infertility Resilience',
  },
];
