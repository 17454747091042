import accessCodeApi, { AccessCodeSortingInformation } from '@apis/accessCode.api';
import DeleteButton from '@components/DeleteButton';
import EditBtn from '@components/EditBtn';
import { AccessPermissionType, Company } from '@models/AccessCode.model';
import routes from '@routes';
import { errorCatchHandler } from '@utils/errorCatch.util';
import { Pagination, Table, message } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ViewAccessCode.scss';

const initPagination = {
  page: 1,
  limit: 6,
};

const initialSortingInfo: AccessCodeSortingInformation = {
  by: '',
  order: '',
  isActive: false,
};

const ViewAccessCodeCompany = () => {
  const navigate = useNavigate();
  const [companies, setCompanies] = useState<Company[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState(initPagination);
  const [totalFeedback, setTotalFeedback] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [sortingInfo, setSortingInfo] = useState<AccessCodeSortingInformation>(initialSortingInfo);

  const companySortingProps = useCallback(
    () => {
      return {
        onHeaderCell: () => {
          return {
            onClick: () => {
              setSortingInfo((prev) => {
                if (prev.by === 'individual') return initialSortingInfo;
                if (prev.by === 'company')
                  return {
                    by: 'individual',
                    order: '',
                    isActive: true,
                  };

                return {
                  by: 'company',
                  order: '',
                  isActive: true,
                };
              });
            },
          };
        },

        sorter: () => {
          return 0;
        },
        showSorterTooltip: isFetching
          ? false
          : {
              title: () => {
                if (
                  !sortingInfo.isActive ||
                  (sortingInfo.by !== 'company' && sortingInfo.by !== 'individual')
                )
                  return 'Click to sort by Whole Company';
                if (sortingInfo.by === 'company') return 'Click to sort by Individual';
                return 'Click to cancel sorting';
              },
              color: '#4D4479',
              style: {
                minWidth: 'fit-content',
              },
            },
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortingInfo, setSortingInfo]
  );

  const getAccessCodes = useCallback(async () => {
    setIsFetching(true);

    const response = await accessCodeApi.getAccessCode(
      pagination.page,
      pagination.limit,
      'company',
      sortingInfo.by,
      sortingInfo.order
    );
    if (response.data.success) {
      //
      const fetchedCompanies = response.data.data.companies;
      const convertCompanies: Company[] = fetchedCompanies.map((company: any) => {
        const newCompany: Company = { ...company };
        return newCompany;
      });
      //
      setCompanies(convertCompanies);
      setTotalFeedback(response.data.data.totalCount);
      setCurrentPage(response.data.data.currentPage);
    }
    setIsFetching(false);
  }, [pagination, sortingInfo]);

  useEffect(() => {
    getAccessCodes();
  }, [pagination, getAccessCodes]);

  const removeAccessCode = useCallback(
    async (accessId: string) => {
      try {
        setDeleteId(accessId);
        const response = await accessCodeApi.deleteAccessCode(accessId);
        if (response.data.success) {
          message.success('Deleted successfully');
          getAccessCodes();
        }
      } catch (err) {
        errorCatchHandler(err);
      } finally {
        setDeleteId('');
      }
    },
    [getAccessCodes]
  );
  const columns: ColumnsType<Company> = [
    {
      title: 'Company/Individual',

      dataIndex: 'companyName',
      key: 'companyName',
      ...companySortingProps(),
    },
    {
      title: 'Email domain',
      dataIndex: 'companyDomain',
      key: 'companyDomain',
    },
    {
      title: 'Member list',
      dataIndex: 'companyUsersFile',
      key: 'companyUsersFile',
      render: (text, record) => {
        return (
          <div>
            {record.accessPermissionType ===
            AccessPermissionType[AccessPermissionType.companyIndividuals] ? (
              <a href={record.companyUsersFile}>
                <div className={`truncate underline`}>{record.fileName}</div>
              </a>
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
    {
      title: 'Created by',
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: (_, record) => {
        return <>{record.createdBy.name}</>;
      },
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => {
        return (
          <>
            {deleteId !== record.id ? (
              <span className={`flex flex-row gap-3`}>
                <EditBtn
                  openEdit={() => {
                    navigate(routes.EditAccessCode.prePath + record.id);
                  }}
                />
                <DeleteButton
                  onDelete={() => {
                    removeAccessCode(record.id);
                  }}
                  deleteClass={'company'}
                />
              </span>
            ) : (
              <div className={`text-red-600 text-lg`}>Deleting...</div>
            )}
          </>
        );
      },
    },
  ];

  const onChange: TableProps<Company>['onChange'] = (page) => {
    if (pagination.page !== page.current) {
      setPagination({
        page: page.current || initPagination.page,
        limit: page.pageSize || initPagination.limit,
      });
      setCurrentPage(page.current as number);
    }
  };

  const onPageChange = (page: number, pageSize: number) => {
    setPagination({ page: page, limit: pageSize });
    setCurrentPage(page);
  };

  return (
    <div className={`flex flex-col gap-11`}>
      <div>
        <Table
          columns={columns}
          rowKey="id"
          dataSource={companies}
          onChange={onChange}
          loading={isFetching}
          pagination={false}
        />
      </div>
      <div className={`flex items-center justify-center w-full h-full`}>
        <Pagination
          onChange={onPageChange}
          pageSize={pagination.limit}
          total={totalFeedback}
          current={currentPage}
          pageSizeOptions={[initPagination.limit, 20, 40]}
        />
      </div>
    </div>
  );
};

export default ViewAccessCodeCompany;
