import { LeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { dataType, diagnosticData } from './UserProfileDetail.page';
import UserDetail from './UserProfileDetail/UserDetail';
import UserOrderDetail from './UserProfileDetail/UserOrderDetail';
import UserProfileDiagnostic from './UserProfileDetail/UserProfileDiagnostic';

export interface OrderDetailType {
  type: string;
  subject: string[];
  dose: string[];
  qty: number[];
  price: number[];
}
export const data: OrderDetailType[] = [
  {
    type: 'Prescription',
    subject: ['Amoxicillin', 'Ibuprofen', 'Loratadine'],
    dose: ['500mg', '200mg', '10mg'],
    qty: [2, 1, 3],
    price: [12.99, 5.49, 3.75],
  },
  {
    type: 'Over-the-Counter',
    subject: ['Paracetamol', 'Vitamin C', 'Cough Syrup'],
    dose: ['500mg', '1000mg', '150ml'],
    qty: [1, 2, 1],
    price: [4.99, 7.5, 8.99],
  },
  {
    type: 'Supplement',
    subject: ['Fish Oil', 'Multivitamin', 'Calcium'],
    dose: ['1000mg', 'One tablet', '600mg'],
    qty: [1, 1, 2],
    price: [10.99, 12.49, 6.89],
  },
  {
    type: 'Medical Equipment',
    subject: ['Bandage', 'Thermometer', 'Blood Pressure Monitor'],
    dose: ['N/A', 'N/A', 'N/A'],
    qty: [3, 1, 1],
    price: [1.99, 15.0, 29.99],
  },
  {
    type: 'Herbal Remedy',
    subject: ['Ginger Tea', 'Echinacea', 'Ginseng'],
    dose: ['One cup', '500mg', '100mg'],
    qty: [5, 1, 2],
    price: [3.5, 9.99, 15.99],
  },
];
const UserOrderDetails = () => {
  const navigate = useNavigate();
  const { userName } = useParams();
  const [active, setActive] = useState<string>('orderDetails');
  const [firstView, setFirstView] = useState<boolean>(true);

  const handleClick = (value: string) => {
    setActive(value);
    if (firstView) {
      setFirstView(false);
    }
  };
  return (
    <div className="p-[40px] h-full overflow-y-auto ">
      <div className={`flex flex-row justify-left items-center gap-8`}>
        <LeftOutlined
          className={`text-primary text-[22px]`}
          onClick={() => {
            navigate(`/user-profile/detail/${userName}`);
          }}
        />
        <div className={`text-3xl text-primary font-bold flex flex-row`}>
          UserProfile<div className="font-normal">/{userName}</div>
          <div className="font-normal">/Order detail</div>
        </div>
      </div>
      {/* <UserDetail userName={userName ?? ''} /> */}
      <div>
        <div className="flex flex-row w-full mt-[40px]">
          {dataType.map((data) => (
            <div
              className={`${
                data.value === active ? 'text-primary' : 'text-[#919191]'
              } text-[20px] font-bold w-fit h-[42px] mr-[16px] cursor-pointer`}
              onClick={() => handleClick(data.value)}
            >
              {data.title}
              {data.value === active ? (
                <div
                  className={`w-full h-[5px]  bg-primary underline-container ${
                    !firstView
                      ? data.value === dataType[0].value
                        ? 'slide-left'
                        : 'slide-right'
                      : ''
                  }  mt-[10px]`}
                ></div>
              ) : (
                <div />
              )}
            </div>
          ))}
        </div>
        <div className="">
          {active === dataType[0].value ? (
            <UserOrderDetail data={data} />
          ) : (
            <UserProfileDiagnostic data={diagnosticData} />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserOrderDetails;
