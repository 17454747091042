import { Dropdown, MenuProps, Skeleton, Spin } from 'antd';
import SearchButton from '@components/SearchButton';
import { useCallback, useEffect, useRef, useState } from 'react';
import './PrivateChatPageStyle.scss';
import UserInformationPrivateChat from '@components/UserInformationPrivateChat';
import ThreeDotsIcon from '@assets/images/icons/three-dots-icon.svg';
import ChatTextArea from './privateChatSubscription/ChatTextArea';
import ViewChatTextArea from './privateChatSubscription/ViewChatTextArea';
import ChatEmptyIcon from '@assets/images/icons/chat-empty-icon.svg';
import PrivateChatDiagnostic from './PrivateChatDiagnostic';
import {
  onSnapshot,
  orderBy,
  doc,
  collection,
  limit,
  query,
  getDocs,
  DocumentData,
  updateDoc,
  where,
  startAfter,
} from 'firebase/firestore';
import { db } from './HandleAccessFirebase';
import accountApi from '@apis/account.api';
import { UserState } from '@state/user/user.state';
import { RootState } from '@state/store';
import { useSelector } from 'react-redux';
import {
  handleSetCurrentChatItem,
  handleSetFirstLoading,
  handleSetLimit,
} from '@state/chat/handleSetStateChat/handleSetStateChat';
import { handleSetIsExpandFull } from '@state/rating/handleSetStateRating/handleSetStateRating';
import { useNavigate } from 'react-router-dom';
import { handleSetAnaProgramIsExpandFull } from '@state/ana-program/handleSetStateAnaProgram/handleSetStateAnaProgram';

export const GroupChatType = [
  {
    title: 'Subscribed',
    value: 'subscribed',
  },
  {
    title: 'Expired',
    value: 'expired',
  },
];

const PrivateChatPage = () => {
  const [groupChatType, setGroupChatType] = useState<String>(
    useSelector((state: RootState) => state.privateChat.subscriptionExpired)
      ? 'expired'
      : 'subscribed'
  );
  const [isShowDiagnostic, setIsShowDiagnostic] = useState<boolean>(false);
  const [lastDoc, setLastDoc] = useState<DocumentData>();
  const [listChat, setListChat] = useState<DocumentData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filterListChat, setFilterListChat] = useState<DocumentData[]>([]);
  const getCurrentChatId = useSelector((state: RootState) => state.privateChat.currentChatItem);
  const [currentUser, setCurrentUser] = useState<UserState>(
    useSelector((state: RootState) => state.userChat)
  );

  const [loadingDetailChat, setLoadingDetailChat] = useState<boolean>(false);
  const [loadListChat, setLoadListChat] = useState<boolean>(false);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [loadMoreEmpty, setLoadMoreEmpty] = useState<boolean>(false);
  const listChatRef = useRef<HTMLDivElement>(null);
  const [searchValue, setSearchValue] = useState<String>('');
  const isFirstLoading = useSelector((state: RootState) => state.privateChat.firstLoading);
  const isExpandFull = useSelector((state: RootState) => state.ratingList.isExpandFull);
  const isAnaProgramExpandFull = useSelector((state: RootState) => state.anaProgram.isExpandFull);
  const userIdAnaProgram = useSelector((state: RootState) => state.anaProgram.userId);
  const [limitList, setLimitList] = useState<number>(
    useSelector((state: RootState) => state.privateChat.limitList)
  );
  const messageRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const scrollToChat = (chatId: string) => {
    const element = messageRefs.current[chatId];
    if (element) {
      element.scrollIntoView();
    }
  };
  const navigate = useNavigate();

  const handleChangeList = () => {
    setLoadListChat(true);
    setLoadingDetailChat(true);
    setLoadMoreEmpty(false);
    handleSetFirstLoading(true);
  };
  const handleSearch = async (value: string) => {
    setSearchValue(value);
    setLoadListChat(true);
    if (!value) {
      setFilterListChat([]);
      setLoadListChat(false);
      return;
    }
    const q = query(
      collection(db, `ana-private-chat/private-chat/sessions`),
      where('subscriptionExpired', '==', groupChatType === GroupChatType[0].value ? false : true),
      orderBy('timestamp', 'desc')
    );
    const getAllListChat = await getDocs(q);

    const getListChat = await Promise.all(
      getAllListChat.docs.map(async (doc) => {
        const userDetail = await handleGetUserDetail(doc.data().senderId);
        return { ...doc.data(), id: doc.id, userDetail };
      })
    );
    const handleFilter = await getListChat.filter((chat) =>
      chat?.userDetail?.userName?.toLowerCase().includes(value.toLowerCase())
    );
    setFilterListChat(handleFilter);
    setLoadListChat(false);
  };

  const handleGetUserDetail = async (userId: string) => {
    try {
      const response = await accountApi.getUser(userId);
      if (response.status === 200) {
        const payload = {
          id: response.data.data.id,
          userName: response.data.data.name,
          email: response.data.data.email,
        };
        return payload;
      }
    } catch (error) {}
  };

  const handleReadMessage = useCallback(
    async (chatId: string, isSetNew: boolean) => {
      if (isSetNew) {
        setLoadingDetailChat(true);
        handleSetCurrentChatItem(chatId);
      }
      if (chatId === getCurrentChatId) {
        setLoadingDetailChat(false);
      }
      setIsShowDiagnostic(false);

      const docRef = doc(db, 'ana-private-chat/private-chat/sessions', chatId);
      await updateDoc(docRef, {
        adminUnreadCount: 0,
      });

      const anaPrivateChat = collection(
        db,
        `ana-private-chat/private-chat/chats/${chatId}/messages`
      );
      const getUnReadMessage = query(anaPrivateChat, where('unread', '==', true));
      const querySnapshot = await getDocs(getUnReadMessage);

      if (querySnapshot.docs.length === 0) {
        return;
      }

      querySnapshot.forEach(async (docSnapshot) => {
        const messageDoc = doc(
          db,
          `ana-private-chat/private-chat/chats/${chatId}/messages`,
          docSnapshot.id
        );
        await updateDoc(messageDoc, {
          unread: false,
        });
      });
    },
    [getCurrentChatId, setLoadingDetailChat, setIsShowDiagnostic]
  );

  const loadMoreListChat = useCallback(async () => {
    if (loadMoreEmpty) return;

    const q = query(
      collection(db, `ana-private-chat/private-chat/sessions`),
      where('subscriptionExpired', '==', groupChatType === GroupChatType[0].value ? false : true),
      orderBy('timestamp', 'desc'),
      startAfter(lastDoc),
      limit(15)
    );

    const getLoadMoreList = await getDocs(q);

    if (getLoadMoreList.docs.length === 0) {
      setLoadMoreEmpty(true);
    } else {
      setLoadingMore(true);
      setLimitList(limitList + 8);
    }
  }, [loadMoreEmpty, groupChatType, lastDoc, limitList]);

  const handleScroll = useCallback(() => {
    const container = listChatRef.current;

    if (container) {
      const total = container.scrollTop + container.clientHeight;
      if (Math.ceil(total) >= container.scrollHeight) {
        loadMoreListChat();
      }
    }
  }, [loadMoreListChat]);

  useEffect(() => {
    const container = listChatRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);

      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll]);

  useEffect(() => {
    const q = query(
      collection(db, `ana-private-chat/private-chat/sessions`),
      where('subscriptionExpired', '==', groupChatType === GroupChatType[0].value ? false : true),
      orderBy('timestamp', 'desc'),
      limit(limitList)
    );
    const unSubscriber = onSnapshot(q, async (snapShot) => {
      if (snapShot.docs.length === 0) {
        setLoadMoreEmpty(true);
        setIsLoading(false);
        setLoadListChat(false);
        setLoadingMore(false);
        setListChat([]);
        return;
      }
      const getListChats = await Promise.all(
        snapShot.docs.map(async (doc) => {
          const userDetail = await handleGetUserDetail(doc.data().senderId);
          if (userDetail) return { ...doc.data(), id: doc.id, userDetail };
          return { ...doc.data(), id: doc.id, userDetail: { id: '', userName: '', email: '' } };
        })
      );

      if (isFirstLoading === true) {
        handleReadMessage(getListChats[0]?.id, true);

        if (isFirstLoading) {
          handleSetFirstLoading(false);
          setCurrentUser(getListChats[0].userDetail);
          setLoadListChat(false);
        }
      } else {
        handleReadMessage(getCurrentChatId, false);
      }
      setListChat(getListChats);
      setLastDoc(snapShot.docs[snapShot.docs.length - 1]);
      setIsLoading(false);
      setLoadingMore(false);
      if (isExpandFull) {
        scrollToChat(getCurrentChatId);
      }
    });
    return () => {
      unSubscriber();
    };
  }, [groupChatType, isFirstLoading, getCurrentChatId, limitList, handleReadMessage, isExpandFull]);

  const items: MenuProps['items'] = [
    {
      label: (
        <p
          className="text-[16px] font-medium h-[43px] pt-[12px] text-[#1E1E1E] m-0 "
          onClick={() => setIsShowDiagnostic(true)}
        >
          View diagnosis results
        </p>
      ),
      key: '0',
    },
  ];

  return (
    <>
      {isLoading ? (
        <Skeleton className="w-full h-full p-[20px]" />
      ) : (
        <>
          <div className="h-full flex flex-row">
            <div className="view-list-container h-full flex flex-col w-[33%]   ">
              <SearchButton onClick={handleSearch}></SearchButton>
              <div className="h-full overflow-y-auto p-[20px]" ref={listChatRef}>
                <div className="mt-5 w-full   ">
                  <div className="w-full p-2 border-2 mt- rounded-lg bg-[#F0F0F0]  h-[40px] flex flex-row">
                    {GroupChatType.map((groupChat) => (
                      <div
                        className={`w-[50%]  h-full text-center font-bold cursor-pointer ${
                          groupChatType === groupChat.value
                            ? 'bg-[white] rounded-lg text-primary bol'
                            : 'text-[#919191]'
                        } `}
                        onClick={() => {
                          handleChangeList();
                          setGroupChatType(groupChat.value);
                        }}
                      >
                        {groupChat.title}
                      </div>
                    ))}
                  </div>
                  {!loadListChat ? (
                    <>
                      {listChat.length > 0 ? (
                        <div>
                          {filterListChat.length > 0 && searchValue
                            ? filterListChat.map((chat) => (
                                <div
                                  className="cursor-pointer"
                                  onClick={() => {
                                    handleReadMessage(chat.id, true);
                                    if (chat.id !== getCurrentChatId) {
                                      setCurrentUser(chat.userDetail);
                                    }
                                  }}
                                >
                                  <UserInformationPrivateChat
                                    userName={chat.userDetail.userName}
                                    adminUnreadCount={chat.adminUnreadCount}
                                    body={chat.lastMessage}
                                    time={chat.timestamp}
                                    isClick={getCurrentChatId === chat.id}
                                  ></UserInformationPrivateChat>
                                </div>
                              ))
                            : listChat.map((chat) => (
                                <div
                                  ref={(el) => (messageRefs.current[chat.id] = el)}
                                  className="cursor-pointer"
                                  onClick={() => {
                                    handleReadMessage(chat.id, true);
                                    setCurrentUser(chat.userDetail);
                                    handleSetLimit(15);
                                  }}
                                >
                                  <UserInformationPrivateChat
                                    userName={chat.userDetail.userName}
                                    body={chat.lastMessage}
                                    time={chat.timestamp}
                                    adminUnreadCount={chat.adminUnreadCount}
                                    isClick={getCurrentChatId === chat.id}
                                  ></UserInformationPrivateChat>
                                </div>
                              ))}
                          {loadingMore && (
                            <Spin className="flex justify-center w-full" size="small" />
                          )}
                        </div>
                      ) : (
                        <div className="flex flex-col pl-[20%] pt-[20%] pr-[20%] h-[65%]">
                          <div className="w-full flex justify-center">
                            <img alt="" src={ChatEmptyIcon} className=" w-[125px] h-[125px] "></img>
                          </div>
                          <div className="w-full flex justify-center">
                            <p className="text-[24px] text-[#DADADA]">No message available</p>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <Skeleton className="p-[20px]" />
                  )}
                </div>
              </div>
            </div>
            {isShowDiagnostic && (
              <div className="w-[34%] h-full view-list-container overflow-y-auto">
                <PrivateChatDiagnostic
                  userId={currentUser ? currentUser.id : ''}
                  onClick={() => setIsShowDiagnostic(false)}
                ></PrivateChatDiagnostic>
              </div>
            )}

            <div className={` ${!isShowDiagnostic ? 'w-[67%]' : 'w-[34%]'}  flex flex-col`}>
              <div className="view-detail-chat-header h-[146px] w-full flex flex-row justify-between">
                <div className=" w-[95%]">
                  <UserInformationPrivateChat
                    userName={currentUser?.userName}
                    body={currentUser ? currentUser.email : ''}
                    isClick={false}
                  ></UserInformationPrivateChat>
                </div>
                <div
                  className={`w-[5%] flex text-center pt-[40px] ${
                    isShowDiagnostic ? 'hidden' : ''
                  }`}
                >
                  <Dropdown
                    menu={{ items }}
                    trigger={['click']}
                    className="drop-down-diagnostic-container"
                  >
                    <p className="h-[32px]" onClick={(e) => e.preventDefault()}>
                      <img
                        alt=""
                        src={ThreeDotsIcon}
                        className="w-[32px] h-[32px] cursor-pointer"
                      ></img>
                    </p>
                  </Dropdown>
                </div>
              </div>
              {(isExpandFull || isAnaProgramExpandFull) && (
                <div className="flex justify-center bg-[#E2EBFF] ">
                  <p
                    className="p-[4px] m-0 text-[16px] cursor-pointer text-[#2F6BFF] underline"
                    onClick={() => {
                      if (isExpandFull) {
                        handleSetIsExpandFull(false);
                        handleSetFirstLoading(true);
                        navigate('/userRating');
                      } else {
                        handleSetAnaProgramIsExpandFull(false);
                        handleSetFirstLoading(true);
                        navigate(`/user-profile/detail/${userIdAnaProgram}/diagnostic-detail`);
                      }
                    }}
                  >
                    To quickly reply screen
                  </p>
                </div>
              )}

              {listChat.length > 0 ? (
                <ViewChatTextArea
                  currentChatItem={getCurrentChatId}
                  loadingDetailChat={loadingDetailChat}
                  finishLoad={() => {
                    setLoadingDetailChat(false);
                  }}
                ></ViewChatTextArea>
              ) : (
                <div className="flex flex-col pl-[20%] pt-[20%] pr-[20%] view-detail-chat-header h-[65%]">
                  <div className="w-full flex justify-center">
                    <img alt="" src={ChatEmptyIcon} className=" w-[125px] h-[125px] "></img>
                  </div>
                  <div className="w-full flex justify-center">
                    <p className="text-[24px] text-[#DADADA]">No message available</p>
                  </div>
                </div>
              )}
              <ChatTextArea
                chatId={getCurrentChatId ? getCurrentChatId : ''}
                currentUserId={currentUser ? currentUser.id : ''}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PrivateChatPage;
