export interface Journey {
  id: string;
  journeyType: string;
  label?: string;
  value?: string;
}

interface CreatedBy {
  id: string;
  name: string;
}

export enum AccessPermissionType {
  wholeCompany,
  companyIndividuals,
}

export interface Company {
  id: string;
  companyName: string;
  companyDomain: string;
  numberOfUses: number;
  companyUsersFile: string;
  accessPermissionType: string;
  fileName: string;
  journeys: Journey[];
  createdBy: CreatedBy;
}

export interface Company {
  id: string;
  companyName: string;
  companyDomain: string;
  numberOfUses: number;
  companyUsersFile: string;
  accessPermissionType: string;

  journeys: Journey[];
  createdBy: CreatedBy;
  fileName: string;
}

export interface Clinic {
  id: string;
  code: string;
  numberOfUses: number;
  startDate: string;
  endDate: string;
  clinicName: string;
  duration: string;
  accessPermissionType: string;
  status: string;
  journeys: Journey[];
  createdBy: CreatedBy;
}

export interface Influencer {
  id: string;
  code: string;
  numberOfUses: number;
  startDate: string;
  endDate: string;
  influencerName: string;
  duration: string;
  accessPermissionType: string;
  status: string;
  journeys: Journey[];
  createdBy: CreatedBy;
}

export default interface AccessCode {
  companies: Company[];

  clinics: Clinic[];
}

export interface AccessCodeData {
  company: Company;

  clinic: Clinic;
}
