import ActiveIcon from '@assets/images/icons/active-expand-icon.svg';
import UnActiveIcon from '@assets/images/icons/not-active-expand-icon.svg';

interface CustomExpandIconProps {
  isActive: boolean;
}
const CustomExpandIcon = ({ isActive }: CustomExpandIconProps) => (
  <span style={{ marginRight: 8 }}>
    {isActive ? (
      <img className="w-[24px] h-[24px]" src={ActiveIcon} alt=""></img>
    ) : (
      <img className="w-[24px] h-[24px]" src={UnActiveIcon} alt=""></img>
    )}
  </span>
);

export default CustomExpandIcon;
