
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RatingListState } from "./rating.state";

const initialState: RatingListState = {
   isExpandFull: false,
   isQuicklyScreen: false,
   userId:'',
   chatId:'',
   subscriptionExpired:false,
   currentPage:1,
};

const ratingListReducer = createSlice({
  name: 'ratingList',
  initialState,
  reducers: {
    setExpandFull(state, action: PayloadAction<RatingListState>) {
      state.isExpandFull =  action.payload.isExpandFull
    },
    removeExpandFull(state) {
        state.isExpandFull =  false
    },
    setQuicklyScreen(state, action: PayloadAction<RatingListState>) {
        state.isQuicklyScreen = action.payload.isQuicklyScreen
    },
    addUserId(state, action: PayloadAction<RatingListState>){
        state.userId = action.payload.userId
    },
    addChatId(state, action: PayloadAction<RatingListState>){
      state.chatId = action.payload.chatId
    }, 
    setCurrentPage(state, action: PayloadAction<RatingListState>){
      state.currentPage = action.payload.currentPage
    },
    setSubscriptionExpired(state, action: PayloadAction<RatingListState>){
      state.subscriptionExpired = action.payload.subscriptionExpired
    }
  }
});

export const { setExpandFull, removeExpandFull, setQuicklyScreen, addUserId,addChatId, setCurrentPage,setSubscriptionExpired } = ratingListReducer.actions;
export default ratingListReducer.reducer;