import OptionType from '@models/Option.model';

export const accessCodeTypes: OptionType[] = [
  {
    value: 'company',
    label: 'Company',
    color: '#CFD47D',
  },
  {
    value: 'clinic',
    label: 'Clinic',
    color: '#219EBC',
  },
];
