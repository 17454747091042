import AccessCodePage from '@pages/access-code/AccessCode.page';
import AddAccessCode from '@pages/access-code/components/AddAccessCode';
import EditAccessCode from '@pages/access-code/components/EditAccessCode';
import { AccessForIndividualsOfCompany } from '@pages/access-code/components/add-access-code/AccessForIndividualsOfCompany';
import { AccessForPatientOfClinic } from '@pages/access-code/components/add-access-code/AccessForPatientOfClinic';
import { AccessForWholeCompany } from '@pages/access-code/components/add-access-code/AccessForWholeCompany';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.less';
import './App.scss';
import ProtectedRoute from './ProtectedRoute';
import NoMatch from './components/NoMatch';
import './https/interceptors.http';
import AffirmationPage from './pages/affirmation/Affirmation.page';
import LoginPage from './pages/auth/Login.page';
import DailyVibe from './pages/daily-vibe/DailyVibe.page';
import routes from './routes';
import PrivateChatPage from '@pages/private-chat/PrivateChatPage';
import UserProfilePage from '@pages/ana-program/user-profile/UserProfile.page';
import ListOrderPage from '@pages/ana-program/list-of-order/ListOrder.page';
import UserProfileDetail from '@pages/ana-program/user-profile/UserProfileDetail.page';
import UserProfileContainer from '@pages/ana-program/user-profile/UserProfileContainer';
import UserOrderDetails from '@pages/ana-program/user-profile/UserOrderDetails.page';
import UserProfileDiagnosticDetail from '@pages/ana-program/user-profile/UserProfileDiagnosticDetail.page';
import UserRating from '@pages/userRating/UserRating.page';

const router = createBrowserRouter([
  {
    path: routes.SignIn.path,
    element: <LoginPage />,
  },
  {
    path: '/',
    element: <ProtectedRoute />,
    errorElement: (
      <NoMatch
        pathName="/"
        className={`flex flex-col items-center w-screen h-screen justify-center space-y-5`}
        title="It looks like you're lost..."
        description="Go to the home page"
      />
    ),
    children: [
      {
        path: routes.Affirmation.path,
        element: <AffirmationPage />,
      },
      {
        path: routes.DailyVibe.path,
        element: <DailyVibe />,
      },
      // {
      //   path: routes.Session.path,
      //   element: <SessionContainer />,
      //   children: [
      //     {
      //       index: true,
      //       element: <SessionPage />,
      //     },
      //     {
      //       path: routes.AddSession.path,
      //       element: <AddSessionPage />,
      //     },
      //     {
      //       path: routes.SessionDetail.path,
      //       element: <SessionDetailPage />,
      //     },
      //     {
      //       path: routes.EditSession.path,
      //       element: <EditSessionPage />,
      //     },
      //   ],
      // },
      // {
      //   path: routes.Navigator.path,
      //   element: <NavigatorPage />,
      // },
      {
        path: routes.AccessCode.path,
        children: [
          {
            index: true,
            element: <AccessCodePage />,
          },
          {
            path: routes.AccessCode.fullPath,
            element: <AccessCodePage />,
          },
        ],
      },
      {
        path: routes.AddAccessCode.path,
        element: <AddAccessCode />,
      },
      {
        path: routes.AccessForWholeCompany.path,
        element: <AccessForWholeCompany />,
      },
      {
        path: routes.EditAccessCode.path,
        element: <EditAccessCode />,
      },
      {
        path: routes.PrivateChat.path,
        element: <PrivateChatPage />,
      },
      {
        path: routes.AccessForCompanyEmployee.path,
        element: <AccessForIndividualsOfCompany />,
      },
      {
        path: routes.AccessForPatient.path,
        element: <AccessForPatientOfClinic />,
      },
      {
        path: routes.UserRating.path,
        element: <UserRating />,
      },
      // {
      //   path: routes.FeedbackPage.path,
      //   element: <FeedbackPage />,
      // },
      {
        path: routes.UserProfile.path,
        element: <UserProfileContainer />,
        children: [
          {
            index: true,
            element: <UserProfilePage />,
          },
          {
            path: routes.UserProfileDetail.path,
            element: <UserProfileDetail />,
          },
          {
            path: routes.UserOrderDetail.path,
            element: <UserOrderDetails />,
          },
          {
            path: routes.UserDiagnosticDetail.path,
            element: <UserProfileDiagnosticDetail />,
          },
        ],
      },
      {
        path: routes.ListOfOrder.path,
        element: <ListOrderPage />,
      },
      // {
      //   path: routes.Telehealth.path,
      //   element: <TeleHealthContainer />,
      //   children: [
      //     {
      //       index: true,
      //       element: <TelehealthPage />,
      //     },
      //     {
      //       path: routes.TelehealthDiagnostic.path,
      //       element: <DiagnosticResult />,
      //     },
      //   ],
      // },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
