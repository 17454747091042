import { Pagination, SelectProps, Skeleton } from 'antd';
import { memo, useCallback, useEffect, useState } from 'react';
import dailyVibeApi from '../../apis/dailyVibe.api';
import NoMatch from '../../components/NoMatch';
import PageHeader from '../../components/PageHeader';
import DailyVibe from '../../models/DailyVibe.model';
import { reasons } from '../../utils/reasons.util';
import CreateDailyVibeModal from './component/CreateDailyVibeModal.page';
import DailyVibeCard from './component/DailyVibeCard.page';

const initPagination = {
  page: 1,
  limit: 6,
};
const DailyVibePage = () => {
  const [dailyVibes, setDailyVibes] = useState<DailyVibe[]>([]);
  const [pagination, setPagination] = useState(initPagination);
  const [totalDailyVibe, setTotalDailyVibe] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [current, setCurrent] = useState(1);
  const [loadingData, setLoadingData] = useState(false);
  const [selectedTag, setSelectedTag] = useState<string | null>(null);
  const [filteredDailyVibes, setFilteredDailyVibes] = useState<DailyVibe[]>([]);

  const handleChange = (value: string | null) => {
    setSelectedTag(value);
    setPagination({ ...pagination, page: 1 });
    setCurrent(1);
  };

  // const options = Array.from(new Set(dailyVibes.flatMap((a) => a.tags)));
  const sortedReasons = reasons.sort((a, b) => a.label.localeCompare(b.label));
  const options: SelectProps['options'] = sortedReasons;

  const getDailyVibes = useCallback(async () => {
    try {
      setLoadingData(true);
      const response = await dailyVibeApi.getDailyVibes(pagination.page, pagination.limit);
      if (response.data.success) {
        setDailyVibes(response.data.data.dailyVibes);
        setTotalDailyVibe(response.data.data.totalCount);
        filterDailyVibes();
      }
    } catch (error) {
      console.error(error);
      setLoadingData(false);
    } finally {
      setLoadingData(false);
    }
  }, [pagination]);

  const filterDailyVibes = useCallback(async () => {
    try {
      setLoadingData(true);
      const response = await dailyVibeApi.filterDailyVibe(
        pagination.page,
        pagination.limit,
        selectedTag ? selectedTag : ''
      );
      if (response.data.success) {
        setFilteredDailyVibes(response.data.data.dailyVibes);
        setTotalDailyVibe(response.data.data.totalCount);
      }
    } catch (error) {
      console.error(error);
      setLoadingData(false);
    } finally {
      setLoadingData(false);
    }
  }, [pagination, selectedTag]);

  useEffect(() => {
    filterDailyVibes();
  }, [pagination, selectedTag]);

  useEffect(() => {
    getDailyVibes();
  }, [pagination, getDailyVibes]);

  const onChange = (page: number, pageSize: number) => {
    setPagination({ page: page, limit: pageSize });
    setCurrent(page);
  };

  const addNewDailyVibe = () => {
    setIsModalOpen(true);
  };
  const onCancelCreate = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className={`flex flex-col h-full p-[40px]`}>
        <PageHeader
          addNewFunction={addNewDailyVibe}
          options={options}
          handleChange={handleChange}
          title="Add new"
          filterPlaceholder="Reasons"
        />
        <div className={`pr-4 overflow-auto`}>
          <div
            className={`grid grid-cols-2 md:grid-cols-1 2xl:grid-cols-2 gap-x-6 gap-y-8 pt-8 pb-8`}
          >
            {dailyVibes.length === 0 ? (
              loadingData ? (
                <Skeleton active avatar title paragraph={{ rows: 3, width: '100%' }} />
              ) : (
                !dailyVibes && (
                  <NoMatch
                    pathName=""
                    className={`absolute w-fit flex flex-col items-center  h-fit justify-center space-y-5 m-auto p-[120px]`}
                    title="No daily vibe was uploaded"
                    description=""
                  />
                )
              )
            ) : filteredDailyVibes.length === 0 ? (
              <>
                <NoMatch
                  pathName=""
                  className={`absolute left-1/2 w-fit font-semibold 
                  text-xl translate-x-[-50%] flex flex-col items-center  
                  h-fit justify-center space-y-5 m-auto p-[120px]`}
                  title="No daily vibe match your search"
                  description=""
                />
              </>
            ) : (
              filteredDailyVibes.map((dailyVibe) => {
                return (
                  <div key={dailyVibe.id}>
                    {loadingData ? (
                      <Skeleton active avatar title paragraph={{ rows: 3, width: '100%' }} />
                    ) : (
                      <DailyVibeCard
                        onEditOk={() => getDailyVibes()}
                        onDeleteOk={() => getDailyVibes()}
                        key={dailyVibe.id}
                        dailyVibe={dailyVibe}
                      />
                    )}
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div className={`flex justify-center pt-4`}>
          {filteredDailyVibes.length > 0 && (
            <Pagination
              current={current}
              pageSizeOptions={[initPagination.limit, 10, 20, 40]}
              total={totalDailyVibe}
              defaultPageSize={initPagination.limit}
              onChange={onChange}
            />
          )}
        </div>
      </div>
      {isModalOpen && (
        <CreateDailyVibeModal
          onModalOk={() => getDailyVibes()}
          onClose={onCancelCreate}
          onOpen={isModalOpen}
          model={'add'}
        />
      )}
    </>
  );
};

export default memo(DailyVibePage);
